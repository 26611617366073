import { useRowCalculations } from '@/composables/useRowCalculations'

export async function useUpdateRowData(name, row, isEditing, tablesData, publicWorks) {
  !isEditing && rowAlreadyExist(name, row, tablesData)
  if (name !== 'haulAndDumpData' && name !== 'optionsData') {
    await useRowCalculations(name, row, publicWorks)
  }
}

const rowAlreadyExist = (name, row, dataArray) => {
  if (!dataArray.value[name].savePayload.find((element) => element.id == row.value.id)) {
    dataArray.value[name].savePayload.push(row.value)
  }
}
