<script setup>
import PropertyBaseFormInput from '@/components/layout/WriteupInputs/PropertyBaseFormInput.vue'
import PropertyBaseSelectInput from '@/components/layout/WriteupInputs/PropertyBaseFormSelect.vue'
import { isValidNumberValue } from '@/utils/utility_methods'

const SelectType = 'select'

const propertyValue = defineModel()
const props = defineProps({
  property: {
    type: Object,
  },
  data: {
    type: Object,
  },
  handleTextWidth: Function,
  handleInput: Function,
})

const formattedValue = (value) => {
  if (value) {
    let input = value.toString().replace(/[^\d.]/g, '')
    return isValidNumberValue(input) ? parseFloat(input).toLocaleString() : input
  } else return ''
}
</script>

<template>
  <div class="inline-block" v-if="property">
    <div v-if="property?.type === SelectType">
      <PropertyBaseSelectInput
        v-model="propertyValue"
        :options="property.defaultValue"
        :label="property.variable"
        @on-change="handleInput(data.element, data.part)"
        @on-input="handleTextWidth($event, data.element, data.part, data.sectionQuestions)"
      />
    </div>
    <div v-else>
      <PropertyBaseFormInput
        :value="formattedValue(propertyValue)"
        type="text"
        :label="property.variable"
        :placeholder="property.variable"
        @on-change="handleInput(data.element, data.part)"
        @on-input="handleTextWidth($event, data.element, data.part, data.sectionQuestions)"
      />
    </div>
  </div>
</template>
