<template>
  <Combobox as="div" v-model="selected">
    <ComboboxLabel class="block capitalize text-sm font-medium text-gray-700">
      {{ label }} <span v-if="required">*</span>
    </ComboboxLabel>
    <div class="relative mt-1">
      <ComboboxInput
        :class="roundedInputClass"
        @input="handleInput"
        :display-value="formatDisplayValue"
      />
      <div
        v-if="!props.roundedInput"
        class="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-indigo-600"
        aria-hidden="true"
      />
      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
      >
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>
      <ComboboxOptions
        v-if="filteredItems.length > 0"
        ref="dropdown"
        @scroll="handleScroll"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="item in filteredItems"
          :key="item.id"
          :value="item"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-3 pr-9',
              {
                'bg-primary-600 text-white': active,
                'text-gray-900': !active,
                'bg-primary-100': selected,
              },
            ]"
          >
            <span :class="['block', { 'font-semibold': selected }]">
              {{ formatDisplayValue(item) }}
            </span>
            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                { 'text-white': active, 'text-primary-600': !active },
              ]"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
        <ComboboxOption v-show="dataLoading">
          <div class="text-center">
            <Spinner :size="5" :color="'text-primary-400'" />
          </div>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup>
import { ref, computed, watch, toRefs, onMounted } from 'vue'
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxLabel,
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

import Spinner from '@/components/layout/Spinner.vue'
import { debounce } from '@/utils/utility_methods'

const props = defineProps({
  searchArray: {
    type: Array,
    default: [],
  },
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  selectedItem: {
    type: [Object, String],
    default: '',
  },
  optionKey: {
    type: [Array, String],
    default: 'name',
  },
  roundedInput: {
    type: Boolean,
    default: true,
  },
  fetchOnScroll: {
    type: Boolean,
    default: false,
  },
  dataLoading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['loadMore', 'filterData'])

const { searchArray, selectedItem, optionKey } = toRefs(props)

const query = ref('')
const dropdown = ref(null)
const selected = ref(selectedItem.value || '')
const options = ref([])

onMounted(() => {
  dropdown.value = document.querySelector('.combobox-options')
})

watch(selectedItem, (newValue) => {
  selected.value = newValue
})

watch(searchArray, (newValue) => {
  options.value = JSON.parse(JSON.stringify(newValue))
})

const roundedInputClass = computed(() => {
  if (props.roundedInput) {
    return 'w-full rounded-md border border-gray-400 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
  } else {
    return 'peer block w-full border-0  py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6'
  }
})

watch(
  () => props.searchArray,
  () => {
    if (!options.value.length) {
      options.value = JSON.parse(JSON.stringify(props.searchArray))
    }
  },
  { immediate: true }
)

const handleInput = (event) => {
  if (props.fetchOnScroll) {
    debouncedEmitFilterData(event.target.value)
  }
  query.value = event.target.value
}

const getOptionText = (option) => {
  if (Array.isArray(optionKey.value)) {
    return optionKey.value
      .map((key) => option[key]?.toString().trim())
      .filter(Boolean)
      .join(' - ')
  }
  return option[optionKey.value]?.toString() || ''
}

const filteredItems = computed(() => {
  if (query.value === '') return options.value
  const normalizedQuery = query.value.toLowerCase().replace(/\s+/g, '')
  return options.value.filter((option) => {
    const optionValue = getOptionText(option).toLowerCase().replace(/\s+/g, '')
    return optionValue.includes(normalizedQuery)
  })
})

const formatDisplayValue = (item) => {
  return item ? getOptionText(item) : ''
}

const handleScroll = () => {
  const dropdownElement = dropdown.value
  if (
    props.fetchOnScroll &&
    dropdownElement.el &&
    dropdownElement.el.scrollTop + dropdownElement.el.clientHeight >=
      dropdownElement.el.scrollHeight
  ) {
    emit('loadMore')
  }
}

const debouncedEmitFilterData = debounce((value) => {
  emit('filterData', value)
}, 500)
</script>
