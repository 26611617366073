<template>
  <Loader
    v-if="
      loading ||
      getProposalStatuses.loading.value ||
      clients.loading.value ||
      companyDataQuery.loading.value ||
      store.userDataLoading.value ||
      allProposalsQuery.loading.value
    "
  />
  <div v-else>
    <Stats :data="companyDataQuery.result.value.organization.proposalsStats" />
    <div class="flex my-8 items-end">
      <div class="w-44 mx-3">
        <BaseAutocomplete
          label="Search Proposal"
          :searchArray="
            allProposals.map((proposal) => ({
              name: proposal.number,
            }))
          "
          @update:modelValue="updateFilter($event, 'number')"
        />
      </div>
      <div
        v-if="
          !(
            store.userData?.roles.nodes.length == 1 &&
            store.userData?.roles.nodes.some((role) => role.name == 'estimator')
          )
        "
        class="w-40 mx-3"
      >
        <BaseAutocomplete
          label="Estimator"
          :searchArray="
            store.userData?.estimators?.nodes.map((estimator) => ({
              ...estimator,
              name: estimator.email,
            }))
          "
          @update:modelValue="updateFilter($event, 'estimator')"
        />
      </div>

      <div class="w-44 mx-3">
        <BaseAutocomplete
          label="Client"
          :searchArray="clients.result.value.clients.nodes"
          @update:modelValue="updateFilter($event, 'client')"
        />
      </div>

      <div class="w-44 mx-3">
        <BaseAutocomplete
          label="Company"
          :optionKey="'companyName'"
          :searchArray="clients.result.value.clients.nodes.filter((client) => client.companyName)"
          @update:modelValue="updateFilter($event, 'company')"
        />
      </div>

      <div class="w-44 mx-3">
        <BaseAutocomplete
          label="Status"
          :searchArray="
            getProposalStatuses.result.value?.statuses.map((status) => ({
              name: status,
            }))
          "
          @update:modelValue="updateFilter($event, 'status')"
        />
      </div>
      <div class="w-44 mx-3">
        <label class="block text-sm font-medium leading-6 text-gray-900" for="Date">Date:</label>
        <input
          class="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          type="date"
          id="birthday"
          name="birthday"
          @change="updateFilter($event, 'date')"
        />
      </div>
      <div>
        <JsonCSV
          :data="allProposalsQuery.result?.value?.proposals?.proposals?.nodes || []"
          :fields="['title', 'status', 'total', 'createdAt', 'publicWorks']"
          :labels="{
            title: 'Title',
            status: 'Status',
            total: 'Total',
            createdAt: 'Date',
            publicWorks: 'Public Works',
          }"
          name="data.csv"
        >
          <button
            type="button"
            class="inline-flex items-center gap-x-1.5 rounded-md px-4 py-1.5 text-sm font-semibold text-white bg-primary-600 hover:bg-primary-700 focus:outline-none shadow-sm focus-visible:outline focus-visible:outline-2"
          >
            <ArrowDownTrayIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
            CSV
          </button>
        </JsonCSV>
      </div>
    </div>
    <div class="my-20 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-primary-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Project
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Client
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Author Email
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Company
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Location
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Total
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Date
                  </th>
                  <th>
                    <div class="flex">
                      <span
                        v-if="filtersApplied"
                        class="inline-flex items-center gap-x-0.5 rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
                      >
                        Clear
                        <button
                          @click="handleClearFilters"
                          type="button"
                          class="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-blue-600/20"
                        >
                          <span class="sr-only">Remove</span>
                          <svg
                            viewBox="0 0 14 14"
                            class="h-3.5 w-3.5 stroke-blue-700/50 group-hover:stroke-blue-700/75"
                          >
                            <path d="M4 4l6 6m0-6l-6 6" />
                          </svg>
                          <span class="absolute -inset-1" />
                        </button>
                      </span>
                      <ArrowPathIcon @click="handleRefresh" class="h-5 w-5 mx-2 cursor-pointer" />
                    </div>
                  </th>
                </tr>
              </thead>

              <tbody class="h-[530px] divide-y divide-gray-200 bg-white">
                <tr
                  class="h-[3.3rem]"
                  v-if="proposals.length !== 0"
                  v-for="proposal in proposals"
                  :key="proposal.id"
                >
                  <td
                    class="truncate max-w-[9rem] whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ proposal.title }}
                  </td>
                  <td
                    class="truncate max-w-[9rem] whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                  >
                    {{ proposal.client.nodes[0].name }}
                  </td>
                  <td
                    class="truncate max-w-[9rem] whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                  >
                    {{ proposal.author?.email }}
                  </td>
                  <td
                    class="truncate max-w-[8rem] whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                  >
                    {{ proposal.client.nodes[0].companyName ?? 'Residence Owner ' }}
                  </td>
                  <td
                    class="truncate max-w-[9rem] whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                  >
                    {{ proposal.client.nodes[0].address.address1 }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ formatNumber(proposal.total) }}
                  </td>
                  <td
                    class="truncate max-w-[9rem] whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                  >
                    {{ proposal.status }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ Moment(proposal.createdAt).format('LL') }}
                  </td>
                  <td>
                    <div class="py-1 pr-3 text-center">
                      <router-link :to="`/estimations/${proposal.id}`" class="block">
                        <p
                          class="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 hover:bg-gray-100 py-0.5 text-xs font-medium leading-5 text-gray-700 shadow-sm"
                        >
                          View
                        </p>
                      </router-link>
                    </div>
                  </td>
                </tr>

                <tr v-else>
                  <td colspan="9" class="px-7 py-32">
                    <p class="text-center">No data</p>
                  </td>
                </tr>
                <tr v-if="proposals.length !== 10 && proposals.length !== 0">
                  <td colspan="9" class="px-7 py-32"></td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="2">
                    <FooterPagination
                      :totalPages="result.proposals.count"
                      :pageInfo="result?.proposals?.proposals.pageInfo"
                      :currentPage="pagination.currentPage"
                      @page-change="handlePageChange"
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import JsonCSV from 'vue-json-csv'
import { useQuery } from '@vue/apollo-composable'
import Moment from 'moment'
import { useRouter, useRoute } from 'vue-router'
import { ArrowPathIcon, ArrowDownTrayIcon } from '@heroicons/vue/20/solid'

import BaseAutocomplete from '@/components/layout/BaseAutocomplete.vue'
import Loader from '@/components/layout/Loader.vue'
import Stats from '@/components/layout/Stats.vue'
import FooterPagination from '@/components/layout/FooterPagination.vue'
import { useUserStore } from '@/stores/user'
import { formatNumber } from '@/utils/utility_methods'

import GET_PROPOSALS from '@/graphql/queries/getProposals.gql'
import GET_PROPOSAL_STATUSES from '@/graphql/queries/getProposalStatuses.gql'
import GET_CLIENTS from '@/graphql/queries/getClients.gql'
import GET_COMPANY_DATA from '@/graphql/queries/getCompanyData.gql'

const router = useRouter()
const route = useRoute()
const store = useUserStore()
const variables = ref({ first: 10, before: null, after: null })
const filters = ref({})
const allProposals = ref([])
const pagination = ref({ currentPage: 1 })
const proposals = ref([])
const getProposalStatuses = useQuery(GET_PROPOSAL_STATUSES)
const clients = useQuery(GET_CLIENTS)
const companyDataQuery = useQuery(GET_COMPANY_DATA)
const { result, loading, refetch } = useQuery(GET_PROPOSALS, variables, {
  fetchPolicy: 'no-cache',
})
const allProposalsQuery = useQuery(GET_PROPOSALS, filters)

onMounted(async () => {
  await companyDataQuery.refetch()
  loadFiltersFromUrl()
})

watch(result, (value) => {
  proposals.value = value.proposals?.proposals?.nodes
})

watch(
  allProposalsQuery.result,
  (newValue) => {
    if (newValue && !filtersApplied.value) {
      allProposals.value = newValue.proposals.proposals.nodes
    } else if (newValue && filtersApplied.value && !allProposals.value.length) {
      allProposals.value = newValue.proposals.proposals.nodes
    }
  },
  { immediate: true }
)

watch(
  filters,
  (newFilters) => {
    if (Object.keys(filters.value).length == 0) {
      router.replace({
        query: {},
      })
    } else {
      router.replace({
        query: {
          ...route.query,
          ...newFilters,
        },
      })
    }
  },
  { deep: true }
)

const updateFilter = (e, name) => {
  if (name == 'client') {
    variables.value[name] = e.name
    filters.value[name] = e.name
  } else if (name == 'company') {
    variables.value[name] = e.companyName
    filters.value[name] = e.companyName
  } else if (name == 'status') {
    variables.value[name] = e.name.toLowerCase()
    filters.value[name] = e.name.toLowerCase()
  } else if (name == 'date') {
    variables.value[name] = new Date(e.target.value).toISOString()
    filters.value[name] = new Date(e.target.value).toISOString()
  } else if (name == 'estimator') {
    variables.value['author'] = e.id
    filters.value['author'] = e.id
  } else if (name == 'number') {
    variables.value[name] = e.name
    filters.value[name] = e.name
  }
}

const loadFiltersFromUrl = () => {
  if (Object.keys(route.query).length) {
    filters.value = {
      ...route.query,
    }
    variables.value = { ...variables.value, ...route.query }
  }
}

const handlePageChange = (controls) => {
  const { currentPage, ...queryVariables } = controls
  variables.value = { ...queryVariables, ...filters.value }
  pagination.value.currentPage = controls.currentPage
}

const filtersApplied = computed(() => Object.keys(filters.value).length !== 0)

const handleRefresh = async () => {
  await refetch()
}

const handleClearFilters = () => {
  filters.value = {}
  variables.value = { first: 10, before: null, after: null }
}
</script>
