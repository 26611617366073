<template>
  <Loader v-if="loading" />
  <div v-else class="my-6">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-base font-semibold text-gray-900">{{ title }}</h1>
      </div>
    </div>
    <div class="mt-3 flex flex-col">
      <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table class="table-fixed min-w-full divide-y divide-gray-300">
              <thead class="bg-primary-100">
                <tr>
                  <th
                    scope="col"
                    class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Project Category
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Sub Total
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Mark Up %
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Mark Up $
                  </th>
                  <th
                    scope="col"
                    class="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Cost
                  </th>

                  <th scope="col" class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="(item, index) in tableData" v-show="item.show" :key="index">
                  <td class="whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                    {{ item.name }}
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    {{ formatNumber(item.subTotal) }}
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    <input
                      v-if="editable && props.showInputs"
                      :value="formatDecimalNumber(item.markupPercentage)"
                      type="number"
                      @change="handleMarkupChange($event, item)"
                      class="block w-20 h-5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <p v-else>{{ item.markupPercentage }}</p>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    <input
                      v-if="editable && props.showInputs"
                      placeholder="0"
                      :value="formatDecimalNumber(item.markupAmount)"
                      type="text"
                      @change="handleMarkupAmountChange($event, item)"
                      class="block w-20 h-5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <p v-else>{{ formatNumber(item.markupAmount) }}</p>
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    {{ formatNumber(item.cost) }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr v-if="tableData">
                  <td
                    class="whitespace-nowrap py-2 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ totals.serviceName }} Totals
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                    {{ formatNumber(totalSum) }}
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm text-gray-500">
                    <input
                      placeholder="0"
                      type="number"
                      :value="formatDecimalNumber(tableTotalsData.markupPercentage)"
                      @change="handleMarkupChange($event, { markup: 'totalMarkup' })"
                      class="block w-20 h-5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                    <input
                      placeholder="0"
                      type="number"
                      :value="formatDecimalNumber(calculateTotals('markupAmount'))"
                      @change="handleMarkupAmountChange($event, { markup: 'totalMarkup' })"
                      class="block w-20 h-5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                  </td>
                  <td class="whitespace-nowrap px-2 py-2 text-sm font-medium text-gray-900">
                    <input
                      v-if="editableTotals"
                      placeholder="0"
                      type="number"
                      :value="
                        formatDecimalNumber(calculateTotals('cost') + tableTotalsData.markupAmount)
                      "
                      @change="
                        handleMarkupAmountChange($event, {
                          markup: 'totalMarkup',
                          cost: 'overridenCost',
                        })
                      "
                      class="block w-24 h-5 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    />
                    <div v-else class="flex items-center">
                      {{ formatNumber(calculateTotals('cost') + tableTotalsData.markupAmount) }}
                      <PencilSquareIcon @click="editableTotals = true" class="ml-2 h-4 w-4" />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { PencilSquareIcon } from '@heroicons/vue/20/solid'
import Loader from '@/components/layout/Loader.vue'
import { formatNumber, formatDecimalNumber } from '@/utils/utility_methods'

import UPDATE_PROPOSAL_SERVICE from '@/graphql/mutations/updateProposalService.gql'
import MARKUP_COST_CALCULATE from '@/graphql/mutations/calculations/markupCostCalculate.gql'
import MARKUP_COST_PERCENTAGE from '@/graphql/mutations/calculations/markupPercentageCalculate.gql'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  data: {
    type: Array,
    default: [],
  },
  editable: {
    type: Boolean,
    default: true,
  },
  totals: {
    type: Object,
    default: {},
  },
  markup: {
    type: Object,
    default: {},
  },
  showInputs: {
    type: Boolean,
    default: true,
  },
})

const loading = ref(false)
const emit = defineEmits(['markupTotal'])
const tableData = ref(null)
const tableTotalsData = ref({ subtotal: 0, markupAmount: 0, markupPercentage: 0, cost: 0 })
const updatedCategory = ref(null)
const editableTotals = ref(false)
const updateProposalService = useMutation(UPDATE_PROPOSAL_SERVICE)
const calculateMarkupCost = useMutation(MARKUP_COST_CALCULATE)
const calculateMarkupPercentage = useMutation(MARKUP_COST_PERCENTAGE)

onMounted(async () => {
  loading.value = true
  tableData.value = await Promise.all(
    props.data.map(async (item) => {
      if (props.markup[item.markup] && props.totals[item.total]) {
        const cost = await calculateMarkupCost.mutate({
          input: {
            markupPercentage: parseFloat(props.markup[item.markup]),
            subTotal: props.totals[item.total],
          },
        })
        item.markupAmount = cost.data.markupCostCalculate.markupCost
        item.cost = cost.data.markupCostCalculate.totalCost
        item.subTotal = props.totals[item.total]
        item.markupPercentage = props.markup.length
          ? props.markup[0][item.markup]
          : props.markup[item.markup]
      } else {
        item.markupAmount = 0
        item.cost = props.totals[item.total] ?? 0
        item.subTotal = props.totals[item.total] ?? 0
        item.markupPercentage = props.markup.length
          ? props.markup[0][item.markup]
          : props.markup[item.markup]
      }
      return item
    })
  )
  if (props.markup['totalMarkup'] != 0 && totalSum != 0) {
    const cost = await calculateMarkupCost.mutate({
      input: {
        markupPercentage: parseFloat(props.markup['totalMarkup']),
        subTotal: totalSum,
      },
    })
    tableTotalsData.value = {
      subtotal: totalSum,
      markupAmount: cost.data.markupCostCalculate.markupCost,
      markupPercentage: props.markup['totalMarkup'],
      cost: cost.data.markupCostCalculate.markupCost,
    }
  }
  loading.value = false
})

const totalSum = Object.entries(props.totals)
  .filter(
    ([key, value]) =>
      typeof value === 'number' &&
      !(
        props.totals['serviceName'] === 'Grading' &&
        (key === 'gradingEquipment' || key === 'gradingLabor')
      )
  )
  .reduce((sum, [key, value]) => sum + value, 0)

const handleMarkupChange = async (e, item) => {
  if (!isNaN(parseFloat(e.target.value))) {
    if (props.markup.length) {
      props.markup.forEach((markupItem) => {
        updateProposalService.mutate({
          input: {
            input: {
              id: markupItem.id,
              [item.markup]: parseFloat(e.target.value),
            },
          },
        })
      })
    } else {
      await updateProposalService.mutate({
        input: {
          input: {
            id: props.markup.id,
            [item.markup]: parseFloat(e.target.value),
          },
        },
      })
    }
    let subTotal = item.markup == 'totalMarkup' ? totalSum : props.totals[item.total]
    const cost = await calculateMarkupCost.mutate({
      input: { markupPercentage: parseFloat(e.target.value), subTotal: subTotal },
    })
    if (item.markup == 'totalMarkup') {
      tableTotalsData.value = {
        subtotal: totalSum,
        markupAmount: cost.data.markupCostCalculate.markupCost,
        markupPercentage: e.target.value,
        cost: cost.data.markupCostCalculate.markupCost,
      }
    } else {
      item.markupAmount = cost.data.markupCostCalculate.markupCost
      item.cost = cost.data.markupCostCalculate.totalCost
      item.markupPercentage = e.target.value
    }
    updatedCategory.value = item
  }
}

const handleMarkupAmountChange = async (e, item) => {
  let subtotals = item.markup == 'totalMarkup' ? totalSum : props.totals[item.total]
  if (subtotals > 0 && !isNaN(parseFloat(e.target.value))) {
    let markupCost =
      item.cost == 'overridenCost'
        ? parseFloat(e.target.value) - parseFloat(calculateTotals('cost'))
        : parseFloat(e.target.value)

    const cost = await calculateMarkupPercentage.mutate({
      input: {
        markupCost: markupCost,
        subTotal: parseFloat(subtotals),
      },
    })
    await updateProposalService.mutate({
      input: {
        input: {
          id: props.markup.id,
          [item.markup]: parseFloat(cost.data.markupPercentageCalculate.markupPercentage),
        },
      },
    })
    if (item.markup == 'totalMarkup') {
      tableTotalsData.value = {
        subtotal: totalSum,
        markupAmount: markupCost,
        markupPercentage: cost.data.markupPercentageCalculate.markupPercentage,
        cost: cost.data.markupPercentageCalculate.totalCost,
      }
    } else {
      item.cost = cost.data.markupPercentageCalculate.totalCost
      item.markupPercentage = cost.data.markupPercentageCalculate.markupPercentage
      item.markupAmount = parseFloat(e.target.value)
    }
  }
}

const markupTotal = (name) => {
  return parseFloat(
    [...tableData.value, tableTotalsData.value].reduce((accumulator, object) => {
      return accumulator + parseFloat(object[name])
    }, 0)
  )
}

const calculateTotals = (name) => {
  if (
    (name == 'markupAmount' && updatedCategory.value) ||
    (name == 'markupAmount' && markupTotal(name) !== 0)
  ) {
    emit('markupTotal', {
      [props.markup.service.name]: {
        markupAmount: markupTotal(name),
        cost: markupTotal('cost'),
        markupPercentage: markupTotal('markupPercentage'),
      },
    })
    updatedCategory.value = null
    return markupTotal(name)
  } else {
    updatedCategory.value = null
    return tableData.value.reduce((accumulator, object) => {
      return accumulator + object[name]
    }, 0)
  }
}
</script>
