<template>
  <div
    class="w-full h-screen bg-no-repeat bg-center bg-cover"
    :style="{ 'background-image': 'url(' + BackgroundImage + ')' }"
  ></div>

  <div
    class="w-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-primary-50 opacity-95 py-14 px-4 shadow sm:rounded-lg sm:px-10">
        <div class="sm:mx-auto mb-14 sm:w-full sm:max-w-md">
          <router-link to="/">
            <img class="mx-auto h-12 w-auto" :src="logo" alt="Workflow" />
          </router-link>
          <h2 class="mt-6 text-center text-3xl font-bold text-gray-900">Password Reset</h2>
          <p v-if="emailSent" class="mt-6 text-center text-sm text-gray-600">
            Reset email has been sent. Check your email for further instructions.
          </p>
          <p v-else class="mt-3 text-center text-sm text-gray-600">
            Enter your email and we'll send you a link to reset your password.
          </p>
        </div>
        <form v-show="!emailSent" @submit.prevent="onSubmit" class="space-y-4">
          <BaseInput
            name="email"
            type="email"
            label="Email"
            :modelValue="email"
            @change="handleEmailChange"
            :error="errors.email"
          />

          <p v-if="errorMessage" class="mt-5 first-letter:capitalize text-failure-500 text-xs">
            {{ errorMessage }}
          </p>
          <BaseButton class="mt-10" fullWidth type="submit" label="Reset your password" />
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import axios from 'axios'
import { useField, useForm } from 'vee-validate'
import { object, string } from 'yup'

import BaseInput from '@/components/layout/BaseInput.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import BackgroundImage from '@/assets/BG_blue.png'
import logo from '@/assets/logo.png'

const errorMessage = ref('')
const emailSent = ref(false)
const validationSchema = object({
  email: string().required().email(),
})

const { handleSubmit, errors } = useForm({
  validationSchema,
})

const { value: email, handleChange: handleEmailChange } = useField('email')

const onSubmit = handleSubmit(async (values) => {
  try {
    await axios.post('/users/password', {
      user: values,
    })
    emailSent.value = true
  } catch (error) {
    console.log('Error :', error)
    errorMessage.value = error.response.data.message
  }
})
</script>
