<template>
  <Loader v-if="isLoading" />
  <div>
    <div class="flex flex-col">
      <div class="border rounded-lg border pb-6 border-gray-200">
        <div>
          <div
            class="bg-primary-100 flex items-center border-b border-gray-200 justify-between px-7"
          >
            <h1 class="py-5 text-2xl font-semibold text-gray-900">Create New Estimation</h1>
          </div>
          <div class="px-10 py-10 border-b">
            <p class="py-6 pl-20 text-xl font-semibold text-gray-900">Client Details</p>
            <div class="grid grid-cols-2 items-center gap-4 max-w-4xl mx-auto px-16">
              <div class="col-span-1 w-80">
                <BaseAutocomplete
                  label="Select client"
                  :searchArray="clientsQuery.items.value"
                  :fetchOnScroll="true"
                  :dataLoading="clientsQuery.loading.value"
                  :optionKey="['companyName', 'name']"
                  :selectedItem="selectedClient"
                  :required="true"
                  @update:modelValue="updateClientAddress"
                  @loadMore="clientsQuery.loadMore"
                  @filterData="(input) => tableFilterFieldsHandler(input, clientsQuery)"
                />
              </div>
              <div class="col-span-1 w-44 self-end">
                <BaseButton @click="clientModal = true" label="Add New Client" />
              </div>

              <div v-if="selectedClient" class="w-80 col-span-1">
                <label class="text-sm font-medium text-gray-700">Client Address</label>
                <address class="text-sm text-block">
                  {{ clientAddress.address1 }}<br />
                  {{ clientAddress.city }}
                  {{ clientAddress.state.name }}
                  {{ clientAddress.zipcode }}<br />
                  {{ clientAddress.state.country.name }}
                </address>
              </div>
            </div>
          </div>
          <div class="px-10 py-6">
            <p class="py-6 pl-20 text-xl font-semibold text-gray-900">Project Details</p>
            <form @submit.prevent="onSubmit" class="space-y-4">
              <div class="grid grid-cols-2 items-center gap-4 max-w-4xl mx-auto px-16">
                <div class="col-span-2">
                  <div class="my-1">
                    <div flex items-start justify-start>
                      <BaseCheckbox label="Public work" v-model="publicWork" />
                    </div>
                  </div>
                  <div class="my-1">
                    <div flex items-center justify-start>
                      <BaseCheckbox
                        :disabled="!selectedClient"
                        :class="{ 'cursor-not-allowed': !selectedClient }"
                        @update:modelValue="updateProjectDetails"
                        label="Same As Client"
                        v-model="sameAsClient"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-80 col-span-1">
                  <BaseInput
                    name="project_name"
                    v-model="projectName"
                    @change="handleProjectNameChange"
                    type="text"
                    label="Project Name"
                    :error="errors.projectName"
                    required
                  />
                </div>
                <div class="w-80 col-span-1">
                  <BaseInput
                    name="name"
                    v-model="supervisorName"
                    @change="handleSupervisorNameChange"
                    type="text"
                    label="Supervisor Name"
                    :error="errors.supervisorName"
                    required
                  />
                </div>
                <div class="w-80 col-span-1">
                  <BaseInput
                    name="name"
                    type="tel"
                    v-model="supervisorPhone"
                    @change="handleSupervisorPhoneChange"
                    label="Supervisor Phone"
                    :error="errors.supervisorPhone"
                  />
                </div>
                <div class="w-80 col-span-1">
                  <BaseInput
                    name="name"
                    type="text"
                    v-model="supervisorEmail"
                    @change="handleSupervisorEmailChange"
                    label="Supervisor Email"
                    :error="errors.supervisorEmail"
                  />
                </div>
                <div class="w-80 col-span-1">
                  <BaseInput
                    name="address1"
                    v-model="address1"
                    @change="handleAddress1Change"
                    type="text"
                    label="Address"
                    :error="errors.address1"
                    required
                  />
                </div>
                <div class="w-80 col-span-1">
                  <BaseInput
                    name="city"
                    v-model="city"
                    type="text"
                    @change="handleCityChange"
                    label="City"
                    :error="errors.city"
                    required
                  />
                </div>
                <div class="col-span-1 w-80">
                  <BaseAutocomplete
                    label="Select State"
                    v-model="state"
                    :searchArray="statesQuery.items.value"
                    :fetchOnScroll="true"
                    :dataLoading="statesQuery.loading.value"
                    :required="true"
                    @loadMore="statesQuery.loadMore"
                    @filterData="(input) => tableFilterFieldsHandler(input, statesQuery)"
                  />
                </div>

                <div class="w-80 col-span-1">
                  <BaseInput
                    name="zipcode"
                    type="text"
                    v-model="zipcode"
                    @change="handleZipcodeChange"
                    label="Zip Code"
                    :error="errors.zipcode"
                    required
                  />
                </div>
                <div class="w-80 col-span-1">
                  <label class="block capitalize text-sm font-medium text-gray-700">Notes</label>
                  <textarea
                    class="w-full mt-1 block px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    id="description"
                    v-model="description"
                    rows="2"
                    placeholder="Your message"
                  ></textarea>
                </div>

                <div class="pt-5 flex justify-start col-span-2">
                  <button
                    type="submit"
                    class="inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal
          title="Add Client Details"
          @outside-modal-click="clientModal = false"
          :open="clientModal"
        >
          <ClientForm
            :states="statesQuery.items.value"
            :dataLoading="statesQuery.loading.value"
            @close-modal="clientModal = false"
            @update-field="populateField"
            @success-toast="toast = { showToast: true, title: 'New Client created successfully!' }"
            @loadMoreStates="statesQuery.loadMore"
            @filterStates="(input) => tableFilterFieldsHandler(input, statesQuery)"
          />
        </Modal>
      </div>
    </div>
  </div>
  <Toast v-if="toast.showToast" :title="toast.title" />
</template>

<script setup>
import { ref } from 'vue'
import { useField, useForm } from 'vee-validate'
import { object, string, boolean } from 'yup'
import { useQuery } from '@vue/apollo-composable'
import { useMutation } from '@vue/apollo-composable'
import { useRouter } from 'vue-router'

import { tableFilterFieldsHandler } from '@/utils/utility_methods'
import Loader from '@/components/layout/Loader.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import BaseAutocomplete from '@/components/layout/BaseAutocomplete.vue'
import BaseInput from '@/components/layout/BaseInput.vue'
import ClientForm from '@/components/layout/ClientForm.vue'
import Modal from '@/components/layout/Modal.vue'
import Toast from '@/components/layout/Toast.vue'
import { usePaginatedQuery } from '@/composables/usePaginatedQuery'
import BaseCheckbox from '@/components/layout/BaseCheckbox.vue'

import CREATE_PROPOSAL from '@/graphql/mutations/createProposal.gql'
import GET_STATES from '@/graphql/queries/getStates.gql'
import GET_CLIENTS from '@/graphql/queries/getClients.gql'
import CREATE_ADDRESS from '@/graphql/mutations/createAddress.gql'
import CREATE_MANAGER from '@/graphql/mutations/createManager.gql'
import GET_PROPOSALS from '@/graphql/queries/getProposals.gql'

const router = useRouter()
const clientModal = ref(false)
const selectedClient = ref(null)
const sameAsClient = ref(false)
const isLoading = ref(false)
const toast = ref({ showToast: false, title: '' })
const proposalSubmitData = ref(null)

const validationSchema = object({
  publicWork: boolean(),
  projectName: string().required(),
  supervisorName: string().required(),
  supervisorPhone: string()
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
  supervisorEmail: string().email().nullable(true),
  address1: string().required(),
  city: string().required(),
  zipcode: string().required(),
  state: object().required(),
})

const statesQuery = usePaginatedQuery(GET_STATES, 'states')
const clientsQuery = usePaginatedQuery(GET_CLIENTS, 'clients')

const getProposals = useQuery(GET_PROPOSALS)
const { mutate: proposalCreate, onDone: onProposalCreate } = useMutation(CREATE_PROPOSAL)
const { mutate: addressCreate, onDone: onAddressCreate } = useMutation(CREATE_ADDRESS)
const { mutate: locationManagerCreate, onDone: onManagerCreate } = useMutation(CREATE_MANAGER)
const { handleSubmit, errors } = useForm({
  validationSchema,
  initialValues: {
    publicWork: false,
  },
})

const { value: address, handleChange: handleAddressChange } = useField('address')
const { value: clientAddress } = useField('clientAddress')
const { value: publicWork } = useField('publicWork')
const { value: projectName, handleChange: handleProjectNameChange } = useField('projectName')
const { value: supervisorName, handleChange: handleSupervisorNameChange } =
  useField('supervisorName')
const { value: supervisorPhone, handleChange: handleSupervisorPhoneChange } =
  useField('supervisorPhone')
const { value: supervisorEmail, handleChange: handleSupervisorEmailChange } =
  useField('supervisorEmail')
const { value: manager, handleChange: handleManagerChange } = useField('manager')
const { value: city, handleChange: handleCityChange } = useField('city')
const { value: address1, handleChange: handleAddress1Change } = useField('address1')
const { value: zipcode, handleChange: handleZipcodeChange } = useField('zipcode')
const { value: state, handleChange: handleStateChange } = useField('state')
const { value: description } = useField('description')

onAddressCreate((result) => {
  locationManagerCreate({
    name: supervisorName.value,
    phone: supervisorPhone.value,
    email: supervisorEmail.value,
    addressId: result.data.addressCreate.address.id,
  })
})

onManagerCreate((managerdata) => {
  proposalCreate({
    input: {
      input: {
        name: proposalSubmitData.value.projectName,
        clientId: selectedClient.value.id,
        supervisorId: managerdata.data.locationManagerCreate.locationManager.id,
        description: proposalSubmitData.value.description,
        publicWorks: proposalSubmitData.value.publicWork,
      },
    },
  })
})

onProposalCreate((proposalData) => {
  getProposals.refetch()
  isLoading.value = false
  router.push(`/estimations/${proposalData.data.proposalCreate.proposal.id}`)
})

function updateClientAddress(clientDetails) {
  selectedClient.value = clientDetails
  clientAddress.value = clientDetails.address
  if (sameAsClient.value) {
    updateProjectDetails(sameAsClient.value)
  }
}

function populateField(field, value) {
  if (field == 'clientDetails') {
    selectedClient.value = value
    clientAddress.value = value.address
  } else if (field == 'clientAddress') {
    clientAddress.value = value
  } else if (field == 'address') {
    address.value = value
  } else if (field == 'manager') {
    manager.value = value
  }
}

function updateProjectDetails(sameAsClient) {
  if (sameAsClient) {
    supervisorName.value = selectedClient.value.name
    supervisorPhone.value = selectedClient.value.phone
    supervisorEmail.value = selectedClient.value.email
    address1.value = selectedClient.value.address.address1
    city.value = selectedClient.value.address.city
    zipcode.value = selectedClient.value.address.zipcode
    state.value = selectedClient.value.address.state
  } else {
    supervisorName.value = ''
    supervisorPhone.value = null
    supervisorEmail.value = ''
    address1.value = ''
    city.value = ''
    zipcode.value = ''
    state.value = ''
  }
}
const onSubmit = handleSubmit((values) => {
  proposalSubmitData.value = values
  isLoading.value = true

  addressCreate({
    address1: values.address1,
    city: values.city,
    zipcode: values.zipcode,
    stateId: values.state.id,
  })
})
</script>
