import { createRouter, createWebHashHistory } from 'vue-router'
import Cookies from 'js-cookie'
import { isAdmin } from '@/utils/utility_methods'

import Home from '@/components/Home.vue'
import Login from '@/components/Login.vue'
import SignUp from '@/components/Signup.vue'
import Reset from '@/components/Reset.vue'
import ResetPassword from '@/components/ResetPassword.vue'
import Proposals from '@/components/Proposals.vue'
import Estimation from '@/components/Estimation.vue'
import NewEstimation from '@/components/NewEstimation.vue'
import EditEstimationDetails from '@/components/EditEstimationDetails.vue'
import MappingToolTab from '@/components/layout/MappingToolTab.vue'
import AsphaltTab from '@/components/layout/AsphaltTab.vue'
import WriteupTab from '@/components/layout/WriteupTab.vue'
import ConcreteTab from '@/components/layout/ConcreteTab.vue'
import GradingDemoTab from '@/components/layout/GradingDemoTab.vue'
import SealCoatTab from '@/components/layout/SealCoatTab.vue'
import StripingTab from '@/components/layout/StripingTab.vue'
import SubcontractorsTab from '@/components/layout/SubcontractorsTab.vue'
import FinalBidTab from '@/components/layout/FinalBidTab.vue'
import ProposalPreview from '@/components/ProposalPreview.vue'
import CompanyProfile from '@/components/CompanyProfile.vue'
import Equipment from '@/components/admin/Equipment.vue'
import Laborer from '@/components/admin/Laborer.vue'
import Miscellaneous from '@/components/admin/Miscellaneous.vue'
import MaterialsCost from '@/components/admin/MaterialsCost.vue'
import StripingWorks from '@/components/admin/StripingWorks.vue'
import Reporting from '@/components/Reporting.vue'
import Dashboard from '@/components/Dashboard.vue'
import OptionsTab from '@/components/layout/OptionsTab.vue'
import UserProfile from '@/components/UserProfile.vue'
import Exclusion from '@/components/admin/Exclusion.vue'
import Inclusion from '@/components/admin/Inclusion.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/estimations', component: Proposals },
  {
    path: '/estimations/:id',
    name: 'estimation',
    redirect: { name: 'Writeup' }, //change to mappingTool after maping tab is working
    component: Estimation,
    children: [
      { name: 'mappingTool', path: 'mapping-tool', component: MappingToolTab },
      { name: 'Writeup', path: 'writeup', component: WriteupTab },
      { name: 'Asphalt', path: 'asphalt', component: AsphaltTab },
      { name: 'Concrete', path: 'concrete', component: ConcreteTab },
      { name: 'Grading', path: 'grading-demo', component: GradingDemoTab },
      { name: 'Seal Coating', path: 'seal-coat', component: SealCoatTab },
      { name: 'Striping', path: 'striping', component: StripingTab },
      { name: 'subcontractors', path: 'subcontractors', component: SubcontractorsTab },
      { name: 'FinalBid', path: 'final-bid', component: FinalBidTab },
      { name: 'preview', path: 'preview', component: ProposalPreview },
      { name: 'Options', path: 'options', component: OptionsTab },
    ],
  },
  {
    path: '/service',
    name: 'service',
    redirect: { name: 'service' },
    children: [
      { name: 'equipment', path: 'equipment', component: Equipment },
      { name: 'laborers', path: 'laborers', component: Laborer },
      { name: 'miscellaneous_costs', path: 'miscellaneous_costs', component: Miscellaneous },
      { name: 'materials_cost', path: 'materials_cost', component: MaterialsCost },
      { name: 'striping_works', path: 'striping_works', component: StripingWorks },
    ],
  },
  { path: '/estimations/new', component: NewEstimation },
  { path: '/estimation/:id/edit', component: EditEstimationDetails },
  { path: '/login', component: Login },
  { path: '/sign_up', component: SignUp },
  { path: '/password/reset', component: Reset },
  { path: '/reset_password', component: ResetPassword },
  { path: '/profile', component: UserProfile },
  {
    path: '/company',
    component: CompanyProfile,
  },
  {
    path: '/reports',
    component: Reporting,
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  {
    path: '/exclusions',
    component: Exclusion,
  },
  {
    path: '/inclusions',
    component: Inclusion,
  },
]

export const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHashHistory(),
  routes, // short for `routes: routes`
})

router.beforeEach(async (to, from) => {
  const isLoggedIn = Cookies.get('csrf_token')
  const PUBLIC_ROUTES = ['/login', '/sign_up', '/password/reset', '/reset_password']

  if (!isLoggedIn && !PUBLIC_ROUTES.includes(to.path)) {
    isAdmin.value = null
    return { path: '/login' }
  }
  if (isLoggedIn && to.path === '/login') {
    return { path: '/' }
  }
  if (isAdmin.value && isLoggedIn && (to.path == '/login' || to.path == '/')) {
    window.location.href = '/admin/dashboard'
  }
})
