import { defineStore } from 'pinia'
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import axios from 'axios'
import Cookies from 'js-cookie'

import { router } from '@/router/index'
import { isAdmin } from '@/utils/utility_methods'

import GET_USER_DATA from '@/graphql/queries/getUserData.gql'
import GET_USER_NOTIFICATIONS from '@/graphql/queries/getUserNotifications.gql'

export const useUserStore = defineStore('user', () => {
  const span = ref('year')
  const userDataQuery = useQuery(GET_USER_DATA, { span: span })
  const userNotificationsQuery = useQuery(GET_USER_NOTIFICATIONS)

  const userData = ref(null)
  const isLoggedIn = computed(() => Cookies.get('csrf_token'))

  const userDataLoading = computed(() => userDataQuery.loading)

  const notifications = ref(null)

  const fetchNotifications = async () => {
    await userNotificationsQuery.refetch()
    notifications.value = userNotificationsQuery.result?.value?.notifications || []
  }

  onMounted(async () => {
    if (!userData.value && isLoggedIn.value) {
      await fetchUserData()
    }
    if (!notifications.value && isLoggedIn.value) {
      await fetchNotifications()
    }
  })

  const fetchUserData = async () => {
    try {
      const { data, errors } = await userDataQuery.refetch()
      if (errors) {
        console.error('GraphQL Errors:', errors)
        throw new Error('Failed to fetch user data')
      }
      userData.value = data?.me || null
    } catch (error) {
      console.error('Error fetching user data:', error)
      throw error
    }
  }

  const pollingInterval = setInterval(() => {
    if (userData.value) {
      fetchNotifications()
    }
  }, 10000)

  onBeforeUnmount(() => {
    clearInterval(pollingInterval)
  })

  const updateSpan = (newSpan) => {
    span.value = newSpan
  }

  const resetUserStore = () => {
    span.value = 'year'
    notifications.value = null
    userData.value = null
  }

  const logoutUser = async () => {
    try {
      isAdmin.value = null
      await axios.get('/users/sign_out')
      resetUserStore()
      router.push('/login')
    } catch (error) {
      console.log('Error :', error)
    }
  }

  return {
    userData,
    userDataLoading,
    notifications,
    fetchUserData,
    fetchNotifications,
    updateSpan,
    logoutUser,
    isLoggedIn,
  }
})
