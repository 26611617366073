<template>
  <label v-if="label" class="block text-sm font-medium text-gray-700"
    >{{ label }}<span v-if="required">*</span></label
  >
  <select
    :value="modelValue"
    class="block w-full px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
    :class="{ 'border-gray-400': !error, 'border-red-400 ': error }"
    v-bind="{
      ...$attrs,
      onChange: ($event) => {
        $emit('update:modelValue', $event.target.value)
      },
    }"
  >
    <option
      v-for="option in options"
      :value="option.name ? option.id : option"
      :key="option.name ? option.name : option"
      :selected="option.name ? option.name : option == modelValue"
    >
      {{ option.name ? option.name : option }}
    </option>
  </select>
  <p v-if="error" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
    {{ error }}
  </p>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
  },
}
</script>
