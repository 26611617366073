<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
  </div>
  <div v-else>
    <Modal :open="showModal" title="Add New Writeup Question">
      <div class="my-14 px-2">
        <div class="w-60">
          <label for="name" class="block text-sm font-bold text-gray-700">Select Worktype </label>
          <select
            class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
            v-model="selectedWorktype"
          >
            <option v-for="option in newWriteupQuestion?.selectOptions" :value="option">
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="py-4">
          <label for="name" class="block text-sm font-bold text-gray-700">Question Template</label>
          <p class="mt-1 text-xs leading-6 text-gray-500">
            Template should contain all mentioned properties wrapped in curly braces.
          </p>
          <p class="mb-3 text-xs text-gray-500" v-html="'Weed Killer with area of {{area}}'"></p>
          <input
            type="text"
            class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
            v-model="newQuestionData.template"
            placeholder="Enter template text"
          />
          <div v-if="Object.keys(newQuestionData).length !== 0">
            <p class="py-2 block text-sm font-bold text-gray-700">Properties</p>
            <div class="flex">
              <div v-for="(value, key) in newQuestionData.templateBindings" :key="key" class="mr-6">
                <span class="flex select-none items-center text-gray-500 sm:text-sm">{{
                  key
                }}</span>
                <select
                  v-if="key == 'smr'"
                  v-model="newQuestionData.inputVariables[key]"
                  class="block w-16 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                >
                  <option v-for="option in ['s', 'm', 'r']" :value="option">
                    {{ option }}
                  </option>
                </select>
                <select
                  v-else-if="key == 'number_of_coats'"
                  v-model="newQuestionData.inputVariables[key]"
                  class="block w-16 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                >
                  <option v-for="option in ['1', '2']" :value="option">
                    {{ option }}
                  </option>
                </select>
                <input
                  v-else
                  type="text"
                  class="block w-16 border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                  v-model="newQuestionData.inputVariables[key]"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        type="button"
        class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
        @click="handleModalCancel"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
        @click="handleAddQuestion"
      >
        Submit
      </button>
    </Modal>
    <ConfirmationModal
      :showModal="confirmationModal.open"
      :title="confirmationModal.title"
      :text="confirmationModal.text"
      @close="confirmationModal.open = false"
      @confirm="handleModalConfirm"
    />
    <div>
      <InfoBar class="print:hidden" message="You can drag and drop selected scoped items" />
      <WriteupSection
        v-for="section in getWriteupSections.result.value.writeupSections.nodes.filter(
          (item) => item.questions.length
        )"
        :title="section.name"
        :populatedQuestions="section.submittedQuestions"
        :subsection="section.children"
        :questions="section.questions"
        @update-data="handleUpdateData"
        @refetch-data="refetchData"
        @add-writeup-question="addNewWriteupQuestion"
        @add-new-writeup-question="handleAddNewWriteupQuestion"
        @update-questions="handleUpdateQuestions"
      />
    </div>
    <div class="print:hidden px-10 py-6 flex justify-start col-span-2">
      <button
        @click="handleSubmit"
        :disabled="disableSubmitButton"
        :class="{
          'cursor-not-allowed': disableSubmitButton,
        }"
        type="submit"
        class="inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
      >
        Submit
      </button>
    </div>

    <div class="print:hidden px-12 py-4 mb-10">
      <div class="sm:grid sm:grid-cols-3">
        <dt class="flex items-center justify-between text-sm font-medium text-gray-500">
          Proposal Map
        </dt>
        <dd class="p-6 w-96">
          <div class="bg-primary-50 mt-1 text-sm text-gray-900 sm:mt-0">
            <ul role="list" class="rounded-md border border-gray-200">
              <li class="flex items-center py-3 pl-3 pr-4 text-sm">
                <div class="flex w-0 flex-1 items-center px-5">
                  <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                  <span class="ml-2 w-0 flex-1 truncate"></span>
                </div>
                <div class="ml-4">
                  <input
                    id="mapImage"
                    class="block w-full text-sm text-primary-600 cursor-pointer"
                    type="file"
                    multiple
                    ref="proposalMapFile"
                    @change="uploadProposalMap"
                  />
                  <p class="pt-1 text-xs text-gray-500">Please upload landscape-oriented images</p>
                </div>
              </li>
            </ul>
          </div>
        </dd>
      </div>
      <ul
        role="list"
        class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
      >
        <li
          v-for="file in proposalByIdQuery.result.value.proposal.mapsWithUrls"
          :key="file.id"
          class="relative"
        >
          <div
            class="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
          >
            <img
              :src="file.url"
              alt="Proposal Map"
              class="pointer-events-none object-cover group-hover:opacity-75"
            />
          </div>
          <div class="flex justify-center my-2">
            <div
              class="w-24 p-1 border border-gray-200 rounded bg-gray-100 flex justify-center gap-x-2"
            >
              <EyeIcon
                @click="handlePreviewImage(file.url)"
                class="h-4 w-4 mx-1 text-gray-500 cursor-pointer"
              />
              <TrashIcon
                @click="handleRemoveMapImage(file)"
                class="h-4 w-4 mx-1 text-gray-500 cursor-pointer"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>

    <div class="print:hidden px-12 py-4 mb-10">
      <div class="sm:grid sm:grid-cols-3">
        <dt class="flex items-center justify-between text-sm font-medium text-gray-500">
          Proposal Attachments
        </dt>
        <dd class="p-6 w-96">
          <div class="bg-primary-50 mt-1 text-sm text-gray-900 sm:mt-0">
            <ul role="list" class="rounded-md border border-gray-200">
              <li class="flex items-center py-3 pl-3 pr-4 text-sm">
                <div class="flex w-0 flex-1 items-center px-5">
                  <PaperClipIcon class="h-5 w-5 flex-shrink-0 text-gray-500" aria-hidden="true" />
                  <span class="ml-2 w-0 flex-1 truncate"></span>
                </div>
                <div class="ml-4">
                  <input
                    id="proposalAttachmentsInput"
                    class="block w-full text-sm text-primary-600 cursor-pointer"
                    type="file"
                    multiple
                    ref="proposalMapAttachments"
                    @change="uploadProposalAttachments"
                  />
                  <p class="pt-1 text-xs text-gray-500">Please upload potrait-oriented images</p>
                </div>
              </li>
            </ul>
          </div>
        </dd>
      </div>

      <ul
        role="list"
        class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8"
      >
        <li
          v-for="file in proposalByIdQuery.result.value.proposal.imagesWithUrls"
          :key="file.id"
          class="relative"
        >
          <div
            class="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100"
          >
            <img
              :src="file.url"
              alt="Proposal Attachment"
              class="pointer-events-none object-cover group-hover:opacity-75"
            />
          </div>
          <div class="flex justify-center my-2">
            <div
              class="w-24 p-1 border border-gray-200 rounded bg-gray-100 flex justify-center gap-x-2"
            >
              <EyeIcon
                @click="handlePreviewImage(file.url)"
                class="h-4 w-4 mx-1 text-gray-500 cursor-pointer"
              />
              <TrashIcon
                @click="handleRemoveProposalAttachments(file)"
                class="h-4 w-4 mx-1 text-gray-500 cursor-pointer"
              />
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { useRoute } from 'vue-router'
import { PaperClipIcon, EyeIcon, TrashIcon } from '@heroicons/vue/20/solid'
import axios from 'axios'

import { getCookie, toSnakeCase } from '@/utils/utility_methods'
import Loader from '@/components/layout/Loader.vue'
import WriteupSection from '@/components/layout/WriteupSection.vue'
import Modal from '@/components/layout/Modal.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import InfoBar from '@/components/layout/InfoBar.vue'

import GET_PROPOSAL_BY_ID from '@/graphql/queries/getProposalById.gql'
import GET_WRITEUP_SECTIONS from '@/graphql/queries/getWriteupSections.gql'
import GET_CONSTRUCTION_WORK_DATA from '@/graphql/queries/getConstructionWorkData.gql'
import CREATE_WRITEUP from '@/graphql/mutations/createWriteup.gql'
import UPDATE_WRITEUP from '@/graphql/mutations/updateWriteup.gql'
import REMOVE_WRITEUP_QUESTION from '@/graphql/mutations/removeWriteupQuestion.gql'
import UPDATE_PROPOSAL_WORK from '@/graphql/mutations/updateProposalWork.gql'

const route = useRoute()
const getWriteupSections = useQuery(GET_WRITEUP_SECTIONS, {
  proposalId: route.params.id,
})
const createWriteup = useMutation(CREATE_WRITEUP)
const updateWriteup = useMutation(UPDATE_WRITEUP)
const proposalRemoveWriteupQuestion = useMutation(REMOVE_WRITEUP_QUESTION)
const updateProposalWork = useMutation(UPDATE_PROPOSAL_WORK)
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const constructionWorkData = useQuery(GET_CONSTRUCTION_WORK_DATA)

const payloadData = ref({})
const proposalMapFile = ref(null)
const proposalMapAttachments = ref([])
const editedQuestions = ref([])
const showModal = ref(false)
const newWriteupQuestion = ref(null)
const selectedWorktype = ref(null)
const newQuestionData = ref({})
const showLoading = ref(false)
const confirmationModal = ref({
  open: false,
  proceed: false,
  title: 'Writeup Questions',
  text: 'There are some uncomplete writeup questions whose values are missing. Do you want to Proceed',
})

const loading = computed(() => {
  return (
    getWriteupSections.loading.value ||
    proposalByIdQuery.loading.value ||
    constructionWorkData.loading.value ||
    showLoading.value
  )
})

const showConfirmationModal = computed(
  () =>
    (!questionValuesFilled.value &&
      !confirmationModal.value.proceed &&
      editedQuestions.value.length == 0) ||
    (editedQuestions.value.length !== 0 &&
      !editQuestionValuesFilled.value &&
      !confirmationModal.value.proceed)
)

watch(selectedWorktype, (value) => {
  if (value) {
    newQuestionData.value = {
      ...value,
      inputVariables: value.properties.reduce(
        (obj, cur) => ({ ...obj, [toSnakeCase(cur.name)]: null }),
        {}
      ),
      templateBindings: value.properties.reduce(
        (obj, cur) => ({ ...obj, [toSnakeCase(cur.name)]: null }),
        {}
      ),
      template: '',
      writeupSection: newWriteupQuestion.value.service,
      newQuestion: true,
    }
  }
})

watch(getWriteupSections.result, (value) => {
  initializePayload()
})

const handleUpdateData = async (questionItem) => {
  if (questionItem.populatedTemplate && !questionItem.showInProposal) {
    showLoading.value = true
    await proposalRemoveWriteupQuestion.mutate({
      input: { ids: [questionItem.id] },
    })
    showLoading.value = false
    await getWriteupSections.refetch()
  } else {
    if (questionItem.showInProposal && Object.keys(questionItem.inputVariables).length !== 0) {
      payloadData.value[questionItem.writeupSection.name] = [
        ...payloadData.value[questionItem.writeupSection.name],
        questionItem,
      ]
    } else if (
      questionItem.showInProposal &&
      Object.keys(questionItem.inputVariables).length == 0
    ) {
      payloadData.value[questionItem.writeupSection.name] = [
        ...payloadData.value[questionItem.writeupSection.name],
        questionItem,
      ]
    } else {
      payloadData.value = {
        ...payloadData.value,
        [questionItem.writeupSection.name]: payloadData.value[
          questionItem.writeupSection.name
        ].filter((filterItem) => filterItem.id !== questionItem.id),
      }
    }
  }
}

const refetchData = async () => {
  await getWriteupSections.refetch()
}

const handleSubmit = async () => {
  if (showConfirmationModal.value) {
    confirmationModal.value.open = true
  } else {
    showLoading.value = true
    if (editedQuestions.value.length !== 0) {
      await Promise.all(
        editedQuestions.value.map(async (question) => {
          await updateWriteup.mutate({
            input: {
              input: {
                id: question.id,
                templateBindings: formatInputVariables(question),
                template: question.template,
                position: question.position,
              },
            },
          })
        })
      )
    } else {
      for (const sectionName of Object.keys(payloadData.value)) {
        const questions = payloadData.value[sectionName]
        if (questions.length) {
          for (let index = 0; index < questions.length; index++) {
            const item = questions[index]
            const positionBase = item.writeupSection.submittedQuestions.length || 0
            const createWriteupResponse = await createWriteup.mutate({
              input: {
                input: {
                  proposalId: route.params.id,
                  writeupQuestionId: item.id,
                  template: item.template,
                  templateBindings: formatInputVariables(item),
                  position: !positionBase ? index + 1 : positionBase + 1 + index,
                },
              },
            })
            if (
              item.workTypes.nodes[0]?.name === 'Static Asphalt' ||
              item.workTypes.nodes[0]?.name === 'Static Seal Coat' ||
              item.workTypes.nodes[0]?.name === 'Static Concrete'
            ) {
              await updateProposalWork.mutate({
                input: {
                  input: {
                    id: createWriteupResponse.data.writeupCreate.submittedQuestion
                      .proposalsServicesWorks.nodes[0].id,
                    hidden: true,
                  },
                },
              })
            }
          }
        }
      }
    }
    confirmationModal.value.open = false
    confirmationModal.value.proceed = false
    showLoading.value = false
    initializePayload()
    editedQuestions.value = []
    showModal.value = false
    selectedWorktype.value = null
    newQuestionData.value = {}
    await proposalByIdQuery.refetch()
    await getWriteupSections.refetch()
  }
}

const handleModalCancel = () => {
  showModal.value = false
  newWriteupQuestion.value = null
}

const handleAddQuestion = async () => {
  payloadData.value[newQuestionData.value.writeupSection.name] = [newQuestionData.value]
  await handleSubmit()
}

const addNewWriteupQuestion = async (questionData) => {
  payloadData.value[questionData.writeupSection.name] = [questionData]
  await handleSubmit()
}

const handleAddNewWriteupQuestion = (work, section, service) => {
  const sectionData = getWriteupSectionData(section ? section.title : service)[0]
  newWriteupQuestion.value = {
    selectOptions: constructionWorkData.result.value[work]['nodes'],
    service: { id: sectionData.id, name: sectionData.name },
  }
  showModal.value = true
}

const getWriteupSectionData = (sectionName) => {
  return getWriteupSections.result.value.writeupSections.nodes.filter(
    (section) => section.name == sectionName
  )
}

const editQuestionValuesFilled = computed(() => {
  if (editedQuestions.value.length > 0) {
    return editedQuestions.value.every((obj) => {
      for (const key in obj.inputVariables) {
        if (obj.inputVariables[key] == '') {
          return false
        }
      }
      return Object.keys(obj.inputVariables).length == obj.templateBoundProperties.length
    })
  }
  return false
})

const questionValuesFilled = computed(() => {
  return Object.values(payloadData.value).some((array) => {
    if (array.length > 0) {
      return array.every((obj) => {
        let result = {}
        for (let key in JSON.parse(obj.templateBindings)) {
          if (
            !obj.inputVariables.hasOwnProperty(key) &&
            JSON.parse(obj.templateBindings)[key] !== null &&
            JSON.parse(obj.templateBindings)[key] !== ''
          ) {
            result[key] = JSON.parse(obj.templateBindings)[key]
          }
        }

        for (const key in obj.inputVariables) {
          if (obj.inputVariables[key] == '') {
            return false
          }
        }
        if (Object.keys(obj.inputVariables).length && Object.keys(result).length) {
          return true
        }

        return (
          Object.keys(obj.inputVariables).length ==
          Object.keys(JSON.parse(obj.templateBindings)).length
        )
      })
    }
    return false
  })
})

const disableSubmitButton = computed(
  () =>
    !Object.values(payloadData.value).some((item) => item.length) &&
    editedQuestions.value.length == 0
)

const initializePayload = () => {
  getWriteupSections.result.value.writeupSections.nodes.forEach((item) => {
    payloadData.value[item.name] = []
    item.children.forEach((child) => {
      payloadData.value[child.name] = []
    })
  })
}

const handleUpdateQuestions = (data) => {
  data.forEach((item) => {
    const exists = editedQuestions.value.some(
      (existingItem) => existingItem.id === item.id && existingItem.itemId === item.itemId
    )
    if (!exists) {
      editedQuestions.value.push(item)
    }
  })
}

const handleModalConfirm = () => {
  confirmationModal.value.proceed = true
  handleSubmit()
}

const uploadProposalMap = async () => {
  const files = Array.from(proposalMapFile.value.files)
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('proposal[maps][]', file)
  })
  try {
    showLoading.value = true
    await axios.patch(
      `/proposals/${proposalByIdQuery.result.value.proposal.id}/attach_maps`,
      formData,
      {
        headers: {
          'X-CSRF-Token': getCookie('csrf_token'),
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    showLoading.value = false
    await proposalByIdQuery.refetch()
  } catch (error) {
    console.log(error)
  }
}

const uploadProposalAttachments = async () => {
  const files = Array.from(proposalMapAttachments.value.files)
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('proposal[images][]', file)
  })
  try {
    showLoading.value = true
    await axios.patch(
      `/proposals/${proposalByIdQuery.result.value.proposal.id}/attach_images`,
      formData,
      {
        headers: {
          'X-CSRF-Token': getCookie('csrf_token'),
          'Content-Type': 'multipart/form-data',
        },
      }
    )
    showLoading.value = false
    await proposalByIdQuery.refetch()
  } catch (error) {
    console.log(error)
  }
}

const handlePreviewImage = (url) => {
  window.open(url, '_blank')
}

const handleRemoveMapImage = async (image) => {
  try {
    showLoading.value = true
    const formData = new FormData()
    formData.append('map_id', image.id)
    await axios.delete(`/proposals/${proposalByIdQuery.result.value.proposal.id}/remove_map`, {
      data: formData,
      headers: {
        'X-CSRF-Token': getCookie('csrf_token'),
      },
    })
    showLoading.value = false
    await proposalByIdQuery.refetch()
  } catch (error) {
    console.log(error)
  }
}

const handleRemoveProposalAttachments = async (attachment) => {
  try {
    showLoading.value = true
    const formData = new FormData()
    formData.append('image_id', attachment.id)

    await axios.delete(
      `/proposals/${proposalByIdQuery.result.value.proposal.id}/remove_image`,

      {
        data: formData,
        headers: {
          'X-CSRF-Token': getCookie('csrf_token'), // Ensure correct CSRF token retrieval
        },
      }
    )
    showLoading.value = false
    await proposalByIdQuery.refetch()
  } catch (error) {
    console.log(error)
  }
}

const formatInputVariables = (item) => {
  return JSON.stringify(item.inputVariables)
}
</script>
