<template>
  <div class="my-12 px-4 py-6 bg-primary-50 border rounded-lg border pb-6 border-gray-200">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <h1 class="text-xl font-semibold text-gray-900">{{ title }}</h1>
      </div>
      <div class="flex justify-center items-center mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <div class="w-80 col-span-1">
          <Dropdown
            :options="rowFields"
            :resetDropdown="resetDropdown"
            :fetchOnScroll="true"
            :dataLoading="rowFieldsLoading"
            @update-selected="updateSelectedOptions"
            @loadMore="loadMoreData"
            @filterData="filterData"
            @removeFilters="filterData"
          />
        </div>
        <div class="ml-4">
          <button
            :disabled="selectedOptions.length == 0"
            @click="handleAddRow"
            type="button"
            class="inline-flex items-center rounded-full border border-transparent bg-primary-600 p-2 text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusIconMini class="h-4 w-4 hover:cursor-pointer" aria-hidden="true" />
          </button>
        </div>
      </div>
    </div>
    <div class="mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
      <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
        <table class="min-w-full divide-y divide-gray-300">
          <thead class="bg-primary-200">
            <tr>
              <th
                v-for="(column, index) in columns"
                scope="col"
                :key="index"
                class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-3"
                :class="{ 'text-center': column.displayName == 'Action' }"
              >
                {{ column.displayName }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white" v-if="!rows.length">
              <td :colspan="columns.length">
                <div class="h-52 relative flex justify-center items-center">
                  <p class="px-2 text-lg text-gray-700">No data to display</p>
                </div>
              </td>
            </tr>
            <tr
              v-else
              v-for="row in rows"
              :key="row.id"
              class="border-b border-gray-200 bg-white"
              :class="{ 'hover:bg-primary-50': !row.create && !(row.id === currentRow?.editId) }"
            >
              <td
                v-for="(column, index) in columns"
                class="hidden py-4 px-3 text-left text-sm sm:table-cell"
                :class="index == 0 ? 'font-medium text-gray-900' : 'text-gray-500'"
              >
                <div class="flex justify-start" v-if="column.edit && row.create">
                  <div class="mt-1 w-16 focus-within:border-indigo-600">
                    <div>
                      <input
                        type="text"
                        :placeholder="column.displayName"
                        :name="`${name}_${column.name}`"
                        :value="formattedValue(row[column.name], column)"
                        @input="handleInput($event, row, column)"
                        @change="handleChange(row)"
                        class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                      />
                    </div>
                  </div>
                </div>

                <div v-else-if="column.edit && row.id === currentRow?.editId">
                  <div class="mt-1 w-16 focus-within:border-indigo-600">
                    <input
                      type="text"
                      :placeholder="column.displayName"
                      :name="`${name}_${column.name}`"
                      :value="formattedValue(currentRow.inputVariables[column.name], column)"
                      @input="handleInput($event, currentRow.inputVariables, column)"
                      @change="handleChange(row)"
                      class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
                      :class="
                        showInputError && column.name == 'loadCutHaulTime' && 'border-red-600'
                      "
                    />
                  </div>
                </div>
                <p v-else-if="row.create">{{ formatDataDisplay(row, column, true) }}</p>
                <div v-else>
                  <div
                    v-if="column.edit && !row[column.name]"
                    class="mt-2 flex items-center w-24 text-failure-700 bg-failure-50 ring-red-600/10 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                  >
                    Value Missing
                  </div>
                  <p v-else>
                    {{ formatDataDisplay(row, column) }}
                  </p>
                </div>
                <div v-show="!row.create && column.displayName == 'Action'">
                  <div
                    v-if="calculationsLoading && row.id === currentRow?.editId"
                    class="flex justify-center"
                  >
                    <Spinner />
                  </div>
                  <div v-else>
                    <div
                      v-if="row.id === currentRow?.editId"
                      class="flex justify-center text-center"
                    >
                      <CheckCircleIcon
                        class="mx-1 h-6 w-6 text-success-500 hover:cursor-pointer"
                        @click="handleEditSave(row)"
                      />
                      <XCircleIcon
                        class="mx-1 h-6 w-6 text-failure-500 hover:cursor-pointer"
                        @click="handleCancel"
                      />
                    </div>
                    <div v-else class="flex justify-center text-center">
                      <PencilSquareIcon
                        class="mx-1 h-5 w-5 hover:cursor-pointer"
                        @click="handleEditRow(row)"
                      />
                      <TrashIcon
                        class="mx-1 h-5 w-5 hover:cursor-pointer"
                        @click="handleRemoveRow(row)"
                      />
                    </div>
                  </div>
                </div>
                <div
                  v-show="row.create && column.displayName == 'Action'"
                  class="flex justify-center"
                >
                  <XMarkIcon
                    class="mx-1 h-6 w-6 hover:cursor-pointer"
                    @click="handleAddRowCancel(row)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot v-if="showSave">
            <tr>
              <th
                scope="row"
                :colspan="columns.length"
                class="hidden px-6 py-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
              >
                <button
                  :disabled="calculationsLoading"
                  type="button"
                  class="inline-flex items-center text-slate-50 justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-success-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                  :class="{ 'cursor-not-allowed': calculationsLoading }"
                  @click="emit('saveData', name)"
                >
                  Save
                </button>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted, computed } from 'vue'
import {
  TrashIcon,
  PencilSquareIcon,
  XCircleIcon,
  XMarkIcon,
  CheckCircleIcon,
} from '@heroicons/vue/20/solid'

import { formatDecimalNumber, isValidNumberValue, formatNumber } from '@/utils/utility_methods'
import Dropdown from '@/components/layout/Dropdown.vue'
import { PlusIcon as PlusIconMini } from '@heroicons/vue/20/solid'
import Spinner from '@/components/layout/Spinner.vue'

const selectedOptions = ref([])
const resetDropdown = ref(false)
const tableInputs = ref([])
const current = ref(null)
const currentRow = ref({
  editId: null,
  deleteId: null,
  data: [],
  properties: [],
  inputVariables: {},
})

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  columns: {
    type: Array,
    default: [],
  },
  rowFields: {
    type: Array,
    default: [],
  },
  rowsData: {
    type: Array,
    default: [],
  },
  initialData: {
    type: Object,
    default: {},
  },
  name: {
    type: String,
    default: '',
  },
  showSave: { type: Boolean, default: false },
  calculationsLoading: { type: Boolean, default: false },
  rowFieldsLoading: { type: Boolean, default: false },
})

const rows = ref(
  props.rowsData.map((item) => ({
    ...item,
    totalHaulDumpCost: item.totalTruckCost + item.totalDumpCost,
    service: item.proposalsServices.nodes,
  }))
)

const emit = defineEmits([
  'addRows',
  'inputChange',
  'saveData',
  'editRow',
  'updateData',
  'removeRow',
  'cancelAddRow',
  'loadMoreData',
  'filterFieldsData',
])

watch(
  () => props.rowsData,
  (data) => {
    rows.value = data?.map((item) => ({
      ...item,
      totalHaulDumpCost: item.totalTruckCost + item.totalDumpCost,
      service: item.proposalsServices?.nodes,
    }))
  }
)

const showInputError = computed(
  () =>
    currentRow.value.inputVariables?.loadCutHaulTime == null ||
    currentRow.value.inputVariables?.loadCutHaulTime == ''
)

onMounted(() => {
  tableInputs.value = props.columns.filter((item) => item.edit)
})

const initialValues = props.columns
  .filter((item) => item.initialValue !== undefined)
  .reduce((acc, cur) => ({ ...acc, [cur.name]: cur.initialValue }), {})

const handleAddRow = () => {
  emit(
    'addRows',
    props.name,
    selectedOptions.value.map((item) => ({
      ...item,
      ...initialValues,
      create: true,
      costDump: item.cost.costDump,
      costTrucking: item.cost.costTrucking,
    }))
  )
  selectedOptions.value = []
  resetDropdown.value = !resetDropdown.value
}

const handleChange = (row) => {
  let valuesFilled = tableInputs.value.every((v) => row[v.name])
  const result = tableInputs.value.every(
    (item) =>
      currentRow.value.inputVariables.hasOwnProperty(camelCaseString(item.name)) &&
      currentRow.value.inputVariables[camelCaseString(item.name)].value !== ''
  )
  if (valuesFilled) {
    current.value = row
    emit('updateData', props.name, current, currentRow.value.editId)
  } else if (result) {
    current.value = { edit: true, ...row, ...currentRow.value.inputVariables }
    emit('updateData', props.name, current, currentRow.value.editId, row)
  }
}

const formattedValue = (value, column) => {
  if (value) {
    let input = value.toString().replace(/[^\d.]/g, '')
    if (column.numberType) {
      return isValidNumberValue(value) ? parseFloat(input).toLocaleString() : input
    }
  } else return ''
}

const handleInput = (event, row, column) => {
  let input = event.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
  row[column.name] = input
  event.target.value = input
}

const handleEditSave = (row) => {
  emit('editRow', props.name, currentRow.value.inputVariables, row)
  currentRow.value = null
}

const handleRemoveRow = (row) => {
  if (props.name == 'equipmentData' || props.name == 'gradingEquipmentData') {
    emit('removeRow', props.name, 'Delete Equipment Data', row)
  } else if (props.name == 'laborerData' || props.name == 'gradingLaborerData') {
    emit('removeRow', props.name, 'Delete Laborer Data', row)
  } else if (props.name == 'miscellaneousCostData') {
    emit('removeRow', props.name, 'Delete MiscellaneousCost Data', row)
  } else if (props.name == 'constructionMaterialsData') {
    emit('removeRow', props.name, 'Delete Construction Materials Data', row)
  } else {
    emit('removeRow', props.name, 'Delete Haul And Dump Data', row)
  }
}

const handleAddRowCancel = (row) => {
  emit(
    'cancelAddRow',
    props.name,
    row.id,
    rows.value.filter((item) => item.id !== row.id)
  )
}

const handleEditRow = (row) => {
  currentRow.value = {
    editId: row.id,
    data: JSON.parse(JSON.stringify(row)),
    inputVariables: {
      area: row.area,
      depth: row.depth,
      loadCutHaulTime: row.loadCutHaulTime,
      costTrucking: row.costTrucking,
      costDump: row.costDump,
    },
  }
}

const updateSelectedOptions = (options) => {
  selectedOptions.value = options
}

const camelCaseString = (string) => {
  if (/^[a-z]+([A-Z][a-z0-9]+)*$/.test(string)) {
    return string
  }
  return string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}
const handleCancel = () => {
  currentRow.value = {
    editId: null,
    deleteId: null,
    data: [],
    properties: [],
    inputVariables: {},
  }
}

const getServiceNames = (serviceArray) => {
  return serviceArray.map((element) => element.service.name).join(', ')
}

const formatDataDisplay = (row, column, createData) => {
  let data = createData
    ? column.nestedKey
      ? row[column.nestedKey][column.name]
      : !row.create && column.name == 'name'
      ? row.workType.name
      : row[column.name] || column.displayName == 'Action'
      ? row[column.name]
      : column.name == 'service'
      ? row.services.nodes[0]?.name
      : 0
    : column.name == 'name'
    ? row.loadCutHaulWork.name
    : column.name == 'service'
    ? getServiceNames(row.service)
    : row[column.name]
    ? formatDecimalNumber(row[column.name])
    : column.numberType && 0
  return column.cost
    ? formatNumber(data)
    : column.numberType
    ? parseFloat(data).toLocaleString()
    : data
}

const loadMoreData = () => {
  emit('loadMoreData')
}

const filterData = (input) => {
  emit('filterFieldsData', input)
}
</script>
