<template>
  <Combobox as="div" v-model="selectedItem" v-slot="{ open }" multiple by="id">
    <div class="relative">
      <template v-if="(isOpen = open)"></template>
      <ComboboxInput
        class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
        placeholder="Add Item"
        @input="handleInput"
      />
      <ComboboxButton
        v-if="selectedItem.length > 0"
        class="absolute inset-y-0 right-6 flex items-center rounded-r-md px-2 focus:outline-none"
      >
        <span
          class="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-primary-800"
          >{{ selectedItem.length }} selected</span
        >
      </ComboboxButton>

      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
      >
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        ref="dropdown"
        v-if="optionItems.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        @scroll="handleScroll"
      >
        <ComboboxOption
          v-for="item in optionItems"
          :key="item.id"
          :value="item.id"
          @click="handleOptionSelect(item)"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-8 pr-4',
              active ? 'bg-primary-600 text-white' : 'text-gray-900',
            ]"
          >
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ item.name || item.title }}
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 left-0 flex items-center pl-1.5',
                active ? 'text-white' : 'text-primary-600',
              ]"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
        <ComboboxOption v-show="dataLoading">
          <div class="text-center">
            <Spinner :size="5" :color="'text-primary-400'" />
          </div>
        </ComboboxOption>
      </ComboboxOptions>

      <ComboboxOptions
        v-else
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption disabled>
          <li class="relative cursor-default select-none py-2 pl-8 pr-4 text-gray-900">
            <span class="block truncate"> No options to display </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup>
import { ref, watch } from 'vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/vue'

import { debounce } from '@/utils/utility_methods'
import Spinner from '@/components/layout/Spinner.vue'

const props = defineProps({
  options: {
    type: Array,
    default: () => [],
  },
  resetDropdown: {
    type: Boolean,
    default: false,
  },
  fetchOnScroll: {
    type: Boolean,
    default: false,
  },
  dataLoading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['updateSelected', 'loadMore', 'filterData', 'removeFilters'])

const optionItems = ref(props.options)
const selectedItem = ref([])
const selectedOptions = ref([])
const query = ref('')
const dropdown = ref(null)
const isOpen = ref(false)

watch(
  () => props.options,
  (data) => {
    optionItems.value = data
  }
)

watch(
  () => props.resetDropdown,
  () => {
    selectedItem.value = []
    selectedOptions.value = []
  }
)

watch(isOpen, (newVal) => {
  if (!newVal) {
    debouncedEmitFilterData('')
  }
})

const handleOptionSelect = (option) => {
  const isSelected = selectedItem.value.includes(option.id)
  if (isSelected) {
    if (!selectedOptions.value.some((o) => o.id === option.id)) {
      selectedOptions.value.push(option)
    }
  } else {
    selectedOptions.value = selectedOptions.value.filter((o) => o.id !== option.id)
  }
  emit('updateSelected', selectedOptions.value)
}

const handleInput = (event) => {
  query.value = event.target.value
  if (props.fetchOnScroll) {
    debouncedEmitFilterData(event.target.value)
  }
}

const handleScroll = () => {
  const dropdownElement = dropdown.value
  if (
    props.fetchOnScroll &&
    dropdownElement?.el &&
    dropdownElement.el.scrollTop + dropdownElement.el.clientHeight >=
      dropdownElement.el.scrollHeight
  ) {
    emit('loadMore')
  }
}

const debouncedEmitFilterData = debounce((value) => {
  emit('filterData', value)
}, 500)
</script>
