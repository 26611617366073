<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
  </div>
  <div v-else>
    <div>
      <TabTable
        title="Subcontractor"
        :columns="subcontractorsTab.subcontractor.columns"
        :rowFields="[{ id: 1, name: 'Add new field' }]"
        :rowsData="tablesData.subcontractorsData.data"
        :showSave="tablesData.subcontractorsData.savePayload.length !== 0"
        :services="result?.proposalsService?.nodes"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        name="subcontractorsData"
      />
    </div>
    <DeleteRowModal
      :showModal="deleteModal.open"
      :title="deleteModal.title"
      @close-modal="deleteModal.open = false"
      @delete-row="handleRemoveRow"
    />
    <Toast v-if="toast.showToast" :title="toast.title" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'

import { subcontractorsTab } from '@/keys/constants'
import TabTable from '@/components/layout/StripingTabTable.vue'
import Toast from '@/components/layout/Toast.vue'
import DeleteRowModal from '@/components/modals/DeleteRowModal.vue'
import Loader from '@/components/layout/Loader.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import { useDeleteTableData } from '@/composables/useDeleteTableData'
import { useEditTableData } from '@/composables/useEditTableData'
import { useUpdateRowData } from '@/composables/useUpdateRowData'
import { useAddRowData } from '@/composables/useAddRowData'

import GET_SERVICE_ID from '@/graphql/queries/getServiceId.gql'
import GET_PROPOSAL_BY_ID from '@/graphql/queries/getProposalById.gql'

const route = useRoute()
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})

const tablesData = ref({
  subcontractorsData: {
    data: [],
    savePayload: [],
  },
})
const deleteModal = ref({ open: false, title: '' })
const deleteRowData = ref({ name: null, data: null })
const toast = ref({ showToast: false, title: '' })
const serviceId = ref(null)

const { result } = useQuery(GET_SERVICE_ID, {
  id: route.params.id,
})

const loading = computed(() => {
  return proposalByIdQuery.loading.value
})

watch(result, (value) => {
  serviceId.value = value.proposalsService.nodes.find((item) => item.service.name == 'Asphalt').id
})

watch(proposalByIdQuery.result, () => {
  tablesData.value.subcontractorsData.data =
    proposalByIdQuery.result.value.proposal.subcontractors.nodes
})

const handleAddRow = (name, rows) => {
  tablesData.value[name].data = [...tablesData.value[name].data, ...rows]
}
const handleCancelAddRow = (name, rowId, rows) => {
  tablesData.value[name].data = rows
  tablesData.value[name].savePayload = tablesData.value[name].savePayload.filter(
    (item) => item.id !== rowId
  )
}

const handleEditRow = async (name, data, payload) => {
  let { responseMessage } = await useEditTableData(name, payload, data)
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleConfirmRemoveRow = (name, title, row) => {
  deleteModal.value.open = true
  deleteRowData.value = { name: name, data: row }
  deleteModal.value.title = title
}

const handleRemoveRow = async () => {
  let { responseMessage } = await useDeleteTableData(
    deleteRowData.value.name,
    deleteRowData.value.data.id,
    deleteRowData.value
  )
  proposalByIdQuery.refetch()
  deleteModal.value = { open: false, title: '' }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleSubmit = async (name, newRow) => {
  let { responseMessage } = await useAddRowData(name, newRow, serviceId.value, tablesData)
  tablesData.value[name] = { data: [], savePayload: [] }
  proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const updateData = async (name, row, isEditing) => {
  if (name == 'subcontractorsData') {
    serviceId.value = result.value.proposalsService.nodes.find(
      (item) => item.service.name == row.value.serviceType
    ).id
  }
  await useUpdateRowData(name, row, isEditing, tablesData)
}
</script>
