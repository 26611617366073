<template>
  <Combobox as="div" v-model="selected" multiple>
    <ComboboxLabel class="block capitalize text-sm font-medium text-gray-700"
      >{{ label }} <span v-if="required">*</span></ComboboxLabel
    >
    <div class="relative mt-1">
      <ComboboxInput
        class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
        :class="error && 'border-failure-600'"
        @change="query = $event.target.value"
        :display-value="displaySelected"
        placeholder="Select items..."
      />

      <ComboboxButton
        class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
      >
        <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </ComboboxButton>

      <ComboboxOptions
        v-if="filteredPeople.length > 0"
        class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ComboboxOption
          v-for="person in filteredPeople"
          :key="person.id"
          :value="valueField ? person[valueField] : person"
          as="template"
          v-slot="{ active, selected }"
        >
          <li
            :class="[
              'relative cursor-default select-none py-2 pl-3 pr-9',
              active ? 'bg-primary-600 text-white' : 'text-gray-900',
            ]"
          >
            <span :class="['block truncate', selected && 'font-semibold']">
              {{ person.name }}
            </span>

            <span
              v-if="selected"
              :class="[
                'absolute inset-y-0 right-0 flex items-center pr-4',
                active ? 'text-white' : 'text-primary-600',
              ]"
            >
              <CheckIcon class="h-5 w-5" aria-hidden="true" />
            </span>
          </li>
        </ComboboxOption>
      </ComboboxOptions>
    </div>
  </Combobox>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  ComboboxLabel,
} from '@headlessui/vue'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid'

const props = defineProps({
  searchArray: {
    type: Array,
    default: [],
  },
  label: {
    type: String,
    default: '',
  },
  required: {
    type: Boolean,
    default: false,
  },
  selectedItem: {
    type: [Object, String],
    default: '',
  },
  valueField: {
    type: String,
    default: null,
  },
  error: { type: String, default: null },
})
let query = ref('')
let selected = ref(props.selectedItem || [])

watch(
  () => props.selectedItem,
  () => {
    selected.value = props.selectedItem
  }
)

let filteredPeople = computed(() =>
  query.value === ''
    ? props.searchArray
    : props.searchArray.filter((person) =>
        person.name
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.value.toLowerCase().replace(/\s+/g, ''))
      )
)

function displaySelected(selectedIds) {
  if (!selectedIds.length) return ''

  const selectedNames = selectedIds.map((id) => {
    const item = props.searchArray.find((service) => service.id === id)
    return item ? item.name : ''
  })

  return selectedNames.join(', ')
}
</script>
