export function usePaginatedQuerySearchFilter() {
  const filterSearch = (searchTerm, updateFilters, filtersApplied) => {
    if (searchTerm == null || (searchTerm === '' && filtersApplied)) {
      updateFilters({ name: '' })
    } else if (searchTerm !== '') {
      updateFilters({ name: searchTerm })
    }
  }

  return {
    filterSearch,
  }
}
