<template>
  <Loader v-if="miscellaneousCostDataQuery.loading.value" />
  <div v-else>
    <Toast v-if="toast.showToast" :title="toast.title" />
    <Modal :open="showModal" title="Add Miscellaneous Cost">
      <form>
        <div class="grid grid-cols-2 gap-4 my-10">
          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700">Name </label>
            <div
              class="mt-1 border-b border-gray-300 focus-within:border-indigo-600"
              :class="errors.name && 'border-failure-600'"
            >
              <input
                type="text"
                v-model="name"
                v-bind="nameAttrs"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Enter Name"
              />
            </div>
            <p v-if="errors.name" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.name }}
            </p>
          </div>

          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700"
              >Unit Of Measurement
            </label>
            <div
              class="mt-1 border-b border-gray-300 focus-within:border-indigo-600"
              :class="errors.name && 'border-failure-600'"
            >
              <input
                type="text"
                v-model="unitOfMeasurement"
                v-bind="unitOfMeasurementAttrs"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="inch, ft, ft^2, USD"
                @input="handleUnitValidation"
              />
            </div>
            <p class="py-1 text-xs text-gray-500">inch, ft, ft^2, USD</p>
            <p
              class="mt-1 first-letter:capitalize text-failure-500 text-xs"
              v-show="showValidationError"
            >
              Invalid unit of measurement
            </p>
            <p
              v-if="errors.unitOfMeasurement"
              class="mt-1 first-letter:capitalize text-failure-500 text-xs"
            >
              {{ errors.unitOfMeasurement }}
            </p>
          </div>
          <div class="w-60">
            <label for="name" class="block text-sm font-medium text-gray-700">Cost Per Unit </label>
            <div
              class="mt-1 border-b border-gray-300 focus-within:border-indigo-600"
              :class="errors.costPerUnit && 'border-failure-600'"
            >
              <input
                type="text"
                v-model="costPerUnit"
                v-bind="costPerUnitAttrs"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="0"
              />
            </div>
            <p
              v-if="errors.costPerUnit"
              class="mt-1 first-letter:capitalize text-failure-500 text-xs"
            >
              {{ errors.costPerUnit }}
            </p>
          </div>

          <div v-if="!editModal" class="w-60">
            <label class="block text-sm font-medium text-gray-700">Category </label>
            <select
              v-model="category"
              v-bind="categoryAttrs"
              class="block w-full border-0 border-b border-gray-300 focus:border-indigo-600 focus:ring-0 sm:text-sm"
              :class="errors.category && 'border-failure-600'"
            >
              <option
                v-for="option in store.categoriesData?.__type.enumValues"
                :value="option.name"
              >
                {{ option.name }}
              </option>
            </select>
            <p v-if="errors.category" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.category }}
            </p>
          </div>

          <div class="col-span-1 w-60">
            <ModalMultiSelect
              label="Select Service"
              :searchArray="store.serviceData.services.nodes"
              v-model="services"
              v-bind="servicesAttrs"
              :required="true"
              valueField="id"
              :error="errors.services"
            />
            <p v-if="errors.services" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.services }}
            </p>
          </div>
        </div>
      </form>

      <div class="flex justify-end mx-5 py-4">
        <button
          type="button"
          class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          @click="
            () => {
              showModal = false
              editModal = false
              handleReset()
            }
          "
        >
          Cancel
        </button>
        <button
          type="submit"
          class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
          @click="handleSubmitData"
          :disabled="showValidationError"
        >
          Submit
        </button>
      </div>
    </Modal>
    <div class="flex my-4 px-1 flex-row justify-between items-center">
      <h1 class="text-2xl font-semibold text-gray-900">Miscellaneous Cost</h1>
      <BaseButton
        type="button"
        @click="showModal = true"
        label="New Miscellaneous Cost"
        size="small"
      />
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-primary-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Name
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Unit Of Measurement
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Cost Per Unit
                  </th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Services
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="miscellaneousCost in miscellaneousCostDataQuery.result.value
                    .miscellaneousCost.nodes"
                  :key="miscellaneousCost.id"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ miscellaneousCost.name }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ miscellaneousCost.unitOfMeasurement }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ miscellaneousCost.costPerUnit }}
                  </td>
                  <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                    {{ miscellaneousCost.services.nodes[0].name }}
                  </td>
                  <td
                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6"
                  >
                    <div class="flex">
                      <div class="px-1">
                        <PencilSquareIcon
                          @click="handleEdit(miscellaneousCost)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="px-1">
                        <TrashIcon
                          @click="handleDelete(miscellaneousCost)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/20/solid'

import Modal from '@/components/layout/Modal.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import ModalMultiSelect from '@/components/layout/ModalMultiSelect.vue'
import Loader from '@/components/layout/Loader.vue'
import Toast from '@/components/layout/Toast.vue'
import { useAdminStore } from '@/stores/admin'
import { debounce } from '@/utils/utility_methods'

import CREATE_MISCELLANEOUS_COST from '@/graphql/mutations/admin/createMiscellaneous.gql'
import DELETE_MISCELLANEOUS_COST from '@/graphql/mutations/admin/deleteMiscellaneous.gql'
import UPDATE_MISCELLANEOUS_COST from '@/graphql/mutations/admin/updateMiscellaneous.gql'
import GET_MISCELLANEOUS_COST_DATA from '@/graphql/queries/getMiscellaneousCostData.gql'
import VALIDATE_UOM from '@/graphql/mutations/ValidUom.gql'

const showModal = ref(false)
const editModal = ref(false)
const toast = ref({ showToast: false, title: '' })
const showValidationError = ref(false)

const validationSchema = computed(() => {
  return yup.object({
    name: yup.string().required('Name is required.'),
    unitOfMeasurement: yup.string().required(),
    costPerUnit: yup.number().required('Cost per unit is required.'),
    services: yup.array().min(1, 'At least one service must be selected'),
    category: editModal.value
      ? yup.string().nullable()
      : yup.string().required('Category is required'),
  })
})

const { defineField, errors, setValues, handleSubmit, handleReset } = useForm({
  initialValues: {
    services: [],
  },
  validationSchema: validationSchema,
})

const [name, nameAttrs] = defineField('name')
const [unitOfMeasurement, unitOfMeasurementAttrs] = defineField('unitOfMeasurement')
const [costPerUnit, costPerUnitAttrs] = defineField('costPerUnit')
const [services, servicesAttrs] = defineField('services')
const [category, categoryAttrs] = defineField('category')

const createMiscellaneousCost = useMutation(CREATE_MISCELLANEOUS_COST)
const deleteMiscellaneousCost = useMutation(DELETE_MISCELLANEOUS_COST)
const updateMiscellaneousCost = useMutation(UPDATE_MISCELLANEOUS_COST)
const validateUOM = useMutation(VALIDATE_UOM)
const miscellaneousCostDataQuery = useQuery(GET_MISCELLANEOUS_COST_DATA)

const store = useAdminStore()

const handleSubmitData = handleSubmit(async (values) => {
  if (editModal.value) {
    await updateMiscellaneousCost.mutate({
      input: {
        input: {
          id: values.id,
          costPerUnit: parseFloat(values.costPerUnit),
          name: values.name,
          services: values.services,
          unitOfMeasurement: values.unitOfMeasurement,
        },
      },
    })
    toast.value = { showToast: true, title: 'Miscellaneous Item updated Successfully!' }
    editModal.value = false
    handleReset()
  } else {
    await createMiscellaneousCost.mutate({
      input: {
        input: {
          costPerUnit: parseFloat(values.costPerUnit),
          unitOfMeasurement: values.unitOfMeasurement,
          name: values.name,
          services: values.services,
          categories: values.category,
        },
      },
    })
    toast.value = { showToast: true, title: 'Miscellaneous Item created Successfully!' }
  }
  showModal.value = false
  await miscellaneousCostDataQuery.refetch()
})

const handleDelete = async (item) => {
  await deleteMiscellaneousCost.mutate({ input: { id: item.id } })
  toast.value = { showToast: true, title: 'Miscellaneous Item removed Successfully!' }
  await miscellaneousCostDataQuery.refetch()
}

const handleEdit = (item) => {
  editModal.value = true
  setValues({
    id: item.id,
    costPerUnit: item.costPerUnit,
    name: item.name,
    unitOfMeasurement: item.unitOfMeasurement,
    services: item.services.nodes.map((service) => service.id),
  })
  showModal.value = true
}

const handleUnitValidation = debounce(async () => {
  const validateResponse = await validateUOM.mutate({
    input: { uom: unitOfMeasurement.value },
  })
  if (validateResponse.data.validUom.validUom) {
    showValidationError.value = false
  } else {
    showValidationError.value = true
  }
}, 800)
</script>
