<template>
  <Loader v-if="companyDataQuery.loading.value" />
  <div v-else>
    <Toast v-if="toast.showToast" :title="toast.title" />
    <Modal :open="showModal" title="Add Exclusion">
      <form>
        <div class="grid grid-cols-2 gap-4 my-10">
          <div class="w-60">
            <label for="title" class="block text-sm font-medium text-gray-700">Title </label>
            <div
              class="mt-1 border-b border-gray-300 focus-within:border-indigo-600"
              :class="errors.title && 'border-failure-600'"
            >
              <input
                type="text"
                v-model="title"
                v-bind="titleAttrs"
                class="block w-full border-0 border-b border-transparent focus:border-indigo-600 focus:ring-0 sm:text-sm"
                placeholder="Enter title"
              />
            </div>
            <p v-if="errors.title" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
              {{ errors.title }}
            </p>
          </div>
        </div>
      </form>

      <div class="flex justify-end mx-5 py-4">
        <button
          type="button"
          class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
          @click="
            () => {
              showModal = false
              editModal = false
              handleReset()
            }
          "
        >
          Cancel
        </button>
        <button
          type="submit"
          class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
          @click="handleSubmitData"
        >
          Submit
        </button>
      </div>
    </Modal>

    <div class="flex my-4 px-1 flex-row justify-between items-center">
      <h1 class="text-2xl font-semibold text-gray-900">Exclusions</h1>
      <BaseButton type="button" @click="showModal = true" label="New Exclusion" size="small" />
    </div>
    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-300">
              <thead class="bg-primary-50">
                <tr>
                  <th
                    scope="col"
                    class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Title
                  </th>
                  <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr
                  v-for="exclusion in companyDataQuery.result.value.organization.exclusions.nodes"
                  :key="exclusion.id"
                >
                  <td
                    class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                  >
                    {{ exclusion.title }}
                  </td>
                  <td class="relative whitespace-nowrap py-4 text-sm font-medium pr-10">
                    <div class="flex justify-end">
                      <div class="px-1">
                        <PencilSquareIcon
                          @click="handleEdit(exclusion)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                      <div class="px-1">
                        <TrashIcon
                          @click="handleDelete(exclusion)"
                          class="h-5 w-5 text-gray-500 hover:cursor-pointer"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { PencilSquareIcon, TrashIcon } from '@heroicons/vue/20/solid'

import Loader from '@/components/layout/Loader.vue'
import Toast from '@/components/layout/Toast.vue'
import Modal from '@/components/layout/Modal.vue'
import BaseButton from '@/components/layout/BaseButton.vue'

import CREATE_EXCLUSION from '@/graphql/mutations/admin/createExclusion.gql'
import DELETE_EXCLUSION from '@/graphql/mutations/admin/deleteExclusion.gql'
import UPDATE_EXCLUSION from '@/graphql/mutations/admin/updateExclusion.gql'
import GET_COMPANY_DATA from '@/graphql/queries/getCompanyData.gql'

const showModal = ref(false)
const editModal = ref(false)
const toast = ref({ showToast: false, title: '' })

const validationSchema = computed(() => {
  return yup.object({
    title: yup.string().required('title is required.'),
  })
})

const { defineField, errors, setValues, handleSubmit, handleReset } = useForm({
  validationSchema: validationSchema,
})

const [title, titleAttrs] = defineField('title')

const createExclusion = useMutation(CREATE_EXCLUSION)
const deleteExclusion = useMutation(DELETE_EXCLUSION)
const updateExclusion = useMutation(UPDATE_EXCLUSION)
const companyDataQuery = useQuery(GET_COMPANY_DATA)

const handleSubmitData = handleSubmit(async (values) => {
  if (editModal.value) {
    await updateExclusion.mutate({
      input: {
        id: values.id,
        title: values.title,
      },
    })
    toast.value = { showToast: true, title: 'Exclusion updated Successfully!' }
    editModal.value = false
    handleReset()
  } else {
    await createExclusion.mutate({
      input: {
        title: values.title,
      },
    })
    toast.value = { showToast: true, title: 'Exclusion created Successfully!' }
  }

  showModal.value = false
  await companyDataQuery.refetch()
})

const handleDelete = async (item) => {
  await deleteExclusion.mutate({ input: { id: item.id } })
  toast.value = { showToast: true, title: 'Exclusion removed Successfully!' }
  await companyDataQuery.refetch()
}

const handleEdit = (item) => {
  editModal.value = true
  setValues({
    id: item.id,
    title: item.title,
  })
  showModal.value = true
}
</script>
