<template>
  <div>
    <dl class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-6">
      <div
        v-for="item in stats"
        :key="item.name"
        class="overflow-hidden rounded-lg bg-primary-700 px-4 py-5 shadow sm:p-6"
      >
        <dt class="truncate text-sm font-medium text-white">{{ item.name }}</dt>
        <dd class="mt-1 text-2xl font-semibold tracking-tight text-white">
          {{
            item.key == 'totalSalesThisMonth' ||
            item.key == 'totalSalesThisWeek' ||
            item.key == 'totalSales'
              ? formatNumber(props.data[item.key])
              : props.data[item.key]
          }}
        </dd>
      </div>
    </dl>
  </div>
</template>

<script setup>
import { formatNumber } from '@/utils/utility_methods'

const stats = [
  { name: 'Total Bid/Week', key: 'totalBidsThisWeek' },
  { name: 'Total Bid/Month', key: 'totalBidsThisMonth' },
  { name: 'Total Bids', key: 'totalBids' },
  { name: 'Total Sales/Week', key: 'totalSalesThisWeek' },
  { name: 'Total Sales/Month', key: 'totalSalesThisMonth' },
  { name: 'Total Sales', key: 'totalSales' },
]

const props = defineProps({
  data: {
    type: Object,
  },
})
</script>
