<template>
  <div v-if="loading">
    <Loader />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
    <TableSkelton />
  </div>
  <div v-else>
    <div>
      <ConstructionMaterials
        title="Asphalt"
        :rowFields="constructionWorkQuery.items.value.filter((item) => item.material)"
        :rowFieldsLoading="constructionWorkQuery.loading.value"
        :rowsData="tablesData.constructionMaterialsData.data"
        name="constructionMaterialsData"
        @add-row="handleSubmit"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cost-updated="toast = { showToast: true, title: 'Cost Per Unit updated Successfully!' }"
        @loadMoreData="constructionWorkQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, constructionWorkQuery)"
      />
    </div>
    <div>
      <TabTable
        name="gradingEquipmentData"
        title="Grading Equipment"
        :columns="tabColumns().gradingDemoEquipment.columns"
        :rowFields="gradingEquipmentQuery.items.value"
        :rowFieldsLoading="gradingEquipmentQuery.loading.value"
        :rowsData="tablesData.gradingEquipmentData.data"
        :showSave="tablesData.gradingEquipmentData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="gradingEquipmentQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, gradingEquipmentQuery)"
      />
    </div>
    <div>
      <TabTable
        name="gradingLaborerData"
        title="Grading Labor"
        :columns="tabColumns(props.proposalData.proposal.publicWorks).labor.columns"
        :rowFields="gradingLaborQuery.items.value"
        :rowFieldsLoading="gradingLaborQuery.loading.value"
        :rowsData="tablesData.gradingLaborerData.data"
        :showSave="tablesData.gradingLaborerData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="gradingLaborQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, gradingLaborQuery)"
      />
    </div>
    <div>
      <TabTable
        name="equipmentData"
        title="Asphalt Equipment"
        :columns="tabColumns().equipment.columns"
        :rowFields="equipmentQuery.items.value"
        :rowFieldsLoading="equipmentQuery.loading.value"
        :rowsData="tablesData.equipmentData.data"
        :showSave="tablesData.equipmentData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="equipmentQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, equipmentQuery)"
      />
    </div>
    <div>
      <TabTable
        name="laborerData"
        title="Asphalt Labor"
        :columns="tabColumns(props.proposalData.proposal.publicWorks).labor.columns"
        :rowFields="laborQuery.items.value"
        :rowFieldsLoading="laborQuery.loading.value"
        :rowsData="tablesData.laborerData.data"
        :showSave="tablesData.laborerData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="laborQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, laborQuery)"
      />
    </div>
    <div>
      <TabTable
        name="miscellaneousCostData"
        title="Asphalt Miscellaneous Cost"
        :columns="tabColumns().miscellaneousCost.columns"
        :rowFields="miscellaneousQuery.items.value"
        :rowFieldsLoading="miscellaneousQuery.loading.value"
        :rowsData="tablesData.miscellaneousCostData.data"
        :showSave="tablesData.miscellaneousCostData.savePayload.length !== 0"
        :calculationsLoading="calculationsLoading"
        @add-rows="handleAddRow"
        @edit-row="handleEditRow"
        @remove-row="handleConfirmRemoveRow"
        @cancel-add-row="handleCancelAddRow"
        @save-data="handleSubmit"
        @update-data="updateData"
        @handle-bulk-edit="handleEditRow"
        @handle-bulk-delete="handleConfirmRemoveRow"
        @loadMoreData="miscellaneousQuery.loadMore"
        @filterFieldsData="(input) => tableFilterFieldsHandler(input, miscellaneousQuery)"
      />
    </div>
    <DeleteRowModal
      :showModal="deleteModal.open"
      :title="deleteModal.title"
      @close-modal="deleteModal.open = false"
      @delete-row="handleRemoveRow"
    />
    <Toast v-if="toast.showToast" :title="toast.title" />
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useQuery } from '@vue/apollo-composable'

import { tabColumns } from '@/keys/constants'
import TabTable from '@/components/layout/TabTable.vue'
import Toast from '@/components/layout/Toast.vue'
import Loader from '@/components/layout/Loader.vue'
import TableSkelton from '@/components/layout/TableSkelton.vue'
import DeleteRowModal from '@/components/modals/DeleteRowModal.vue'
import ConstructionMaterials from '@/components/layout/ConstructionMaterials.vue'
import { useDeleteTableData } from '@/composables/useDeleteTableData'
import { useEditTableData } from '@/composables/useEditTableData'
import { useUpdateRowData } from '@/composables/useUpdateRowData'
import { useAddRowData } from '@/composables/useAddRowData'
import { usePaginatedQuery } from '@/composables/usePaginatedQuery'
import {
  filterGradingItems,
  filterServiceItems,
  filterConstructionMaterialsItems,
  tableFilterFieldsHandler,
} from '@/utils/utility_methods'

import GET_ASPHALT_CONSTRUCTION_WORK_DATA from '@/graphql/queries/getAsphaltConstructionWork.gql'
import GET_ASPHALT_EQUIPMENT_DATA from '@/graphql/queries/getAsphaltEquipment.gql'
import GET_ASPHALT_LABOR_DATA from '@/graphql/queries/getAsphaltLabor.gql'
import GET_ASPHALT_MISCELLANEOUS_DATA from '@/graphql/queries/getAsphaltMiscellaneous.gql'
import GET_GRADING_EQUIPMENT_DATA from '@/graphql/queries/getGradingEquipment.gql'
import GET_GRADING_LABOR_DATA from '@/graphql/queries/getGradingLabor.gql'
import GET_PROPOSAL_BY_ID from '@/graphql/queries/getProposalById.gql'
import GET_PROPOSAL_SERVICE from '@/graphql/queries/getProposalService.gql'
import GET_WRITEUP_SECTIONS from '@/graphql/queries/getWriteupSections.gql'

const props = defineProps({
  proposalData: {
    type: Object,
  },
})

const emit = defineEmits(['disableFinalbidTab'])

const tablesData = ref({
  laborerData: { data: [], savePayload: [] },
  equipmentData: { data: [], savePayload: [] },
  miscellaneousCostData: { data: [], savePayload: [] },
  constructionMaterialsData: { data: [], savePayload: [] },
  gradingEquipmentData: { data: [], savePayload: [] },
  gradingLaborerData: { data: [], savePayload: [] },
})
const deleteModal = ref({ open: false, title: '' })
const deleteRowData = ref({ name: null, data: null, ids: [] })
const asphaltServiceId = ref(null)
const gradingServiceId = ref(null)
const calculationsLoading = ref(false)
const route = useRoute()
const toast = ref({ showToast: false, title: '' })

const constructionWorkQuery = usePaginatedQuery(
  GET_ASPHALT_CONSTRUCTION_WORK_DATA,
  'asphaltConstructionWork'
)
const equipmentQuery = usePaginatedQuery(GET_ASPHALT_EQUIPMENT_DATA, 'asphaltEquipment')
const laborQuery = usePaginatedQuery(GET_ASPHALT_LABOR_DATA, 'asphaltLaborer')
const miscellaneousQuery = usePaginatedQuery(
  GET_ASPHALT_MISCELLANEOUS_DATA,
  'asphaltMiscellaneousCost'
)
const gradingEquipmentQuery = usePaginatedQuery(GET_GRADING_EQUIPMENT_DATA, 'gradingEquipment')
const gradingLaborQuery = usePaginatedQuery(GET_GRADING_LABOR_DATA, 'gradingLaborers')
const proposalByIdQuery = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const { result } = useQuery(GET_PROPOSAL_SERVICE, {
  id: route.params.id,
})
const getWriteupSections = useQuery(GET_WRITEUP_SECTIONS, {
  proposalId: route.params.id,
})

const loading = computed(() => proposalByIdQuery.loading.value)

watch(tablesData.value, (value) => {
  emit('disableFinalbidTab', disableFinalBidTab.value)
})

const disableFinalBidTab = computed(() =>
  tablesData.value.constructionMaterialsData.data.some((item) => !item.isComplete)
)

watch(result, (value) => {
  let asphaltService = value.proposalsService.nodes.find((item) => item.service.name == 'Asphalt')
  asphaltServiceId.value = asphaltService.id
  gradingServiceId.value = value.proposalsService.nodes.find(
    (item) => item.service.name == 'Grading'
  ).id
})

watch([asphaltServiceId, proposalByIdQuery.result], async () => {
  if (asphaltServiceId.value && proposalByIdQuery.result.value) {
    tablesData.value.gradingLaborerData.data = filterGradingItems(
      proposalByIdQuery.result.value.proposal.laborers.nodes,
      asphaltServiceId.value
    )
    tablesData.value.gradingEquipmentData.data = filterGradingItems(
      proposalByIdQuery.result.value.proposal.equipments.nodes,
      asphaltServiceId.value
    )
    tablesData.value.laborerData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.laborers.nodes,
      asphaltServiceId.value
    )

    tablesData.value.equipmentData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.equipments.nodes,
      asphaltServiceId.value
    )

    tablesData.value.miscellaneousCostData.data = filterServiceItems(
      proposalByIdQuery.result.value.proposal.miscellaneousCosts.nodes,
      asphaltServiceId.value
    )

    tablesData.value.constructionMaterialsData.data = filterConstructionMaterialsItems(
      proposalByIdQuery.result.value.proposal.constructionMaterial.nodes,
      asphaltServiceId.value
    )
  }
})

const handleAddRow = (name, rows) => {
  tablesData.value[name].data = [...tablesData.value[name].data, ...rows]
}

const handleCancelAddRow = (name, rowId, rows) => {
  tablesData.value[name].data = rows
  tablesData.value[name].savePayload = tablesData.value[name].savePayload.filter(
    (item) => item.id !== rowId
  )
}

const handleEditRow = async (name, payload, row) => {
  let { responseMessage } = await useEditTableData(name, payload, row)
  await proposalByIdQuery.refetch()
  if (name == 'constructionMaterialsData') {
    await getWriteupSections.refetch()
  }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleConfirmRemoveRow = (name, title, rows) => {
  deleteModal.value.open = true
  deleteRowData.value = {
    name: name,
    data: rows,
    ids: name !== 'constructionMaterialsData' ? rows.map((item) => item.id ?? item) : [],
  }
  deleteModal.value.title = title
}

const handleRemoveRow = async () => {
  let { responseMessage } = await useDeleteTableData(
    deleteRowData.value.name,
    deleteRowData.value.name !== 'constructionMaterialsData'
      ? deleteRowData.value.ids
      : deleteRowData.value.data.id,
    deleteRowData.value.data.proposalsWorkProperties
  )
  proposalByIdQuery.refetch()
  if (deleteRowData.value.name == 'constructionMaterialsData') {
    await getWriteupSections.refetch()
  }
  deleteModal.value = { open: false, title: '' }
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const handleSubmit = async (name, newRow) => {
  let { responseMessage } = await useAddRowData(
    name,
    newRow,
    asphaltServiceId.value,
    tablesData,
    gradingServiceId.value
  )
  tablesData.value[name] = { data: [], savePayload: [] }
  await proposalByIdQuery.refetch()
  toast.value = { showToast: responseMessage.value.showToast, title: responseMessage.value.title }
}

const updateData = async (name, row, isEditing, rowData) => {
  calculationsLoading.value = true
  await useUpdateRowData(
    name,
    row,
    isEditing,
    tablesData,
    props.proposalData.proposal.publicWorks,
    rowData
  )
  calculationsLoading.value = false
}
</script>
