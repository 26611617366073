<template>
  <form @submit.prevent="onSubmit" class="space-y-4">
    <div class="grid grid-cols-2 gap-6">
      <div>
        <BaseInput
          name="name"
          type="text"
          label="Client Name"
          :modelValue="name"
          @change="handleNameChange"
          :error="errors.name"
          required
        />
      </div>
      <div>
        <BaseInput
          name="email"
          type="email"
          label="Email"
          :modelValue="email"
          @change="handleEmailChange"
          :error="errors.email"
        />
      </div>

      <div>
        <BaseInput
          name="phone"
          type="tel"
          label="Phone Number"
          :modelValue="phone"
          @change="handlePhoneChange"
          :error="errors.phone"
        />
      </div>

      <div>
        <BaseInput
          name="companyName"
          type="text"
          label="Company Name"
          :modelValue="companyName"
          @change="handleCompanyNameChange"
          :error="errors.companyName"
        />
      </div>

      <div>
        <BaseCheckbox label="Residence Owner" v-model="residenceOwner" />
      </div>

      <div class="col-span-2">
        <h1 class="text-md font-medium leading-6 text-gray-900">Address</h1>
      </div>

      <div>
        <BaseInput
          name="primary address"
          type="text"
          label="Address"
          :modelValue="address"
          @change="handleAddressChange"
          :error="errors.address"
          required
        />
      </div>
      <div>
        <BaseInput
          name="city"
          type="text"
          label="City"
          :modelValue="city"
          @change="handleCityChange"
          :error="errors.city"
          required
        />
      </div>
      <div>
        <BaseInput
          name="zipcode"
          type="text"
          label="Zip Code"
          :modelValue="zipcode"
          @change="handleZipcodeChange"
          :error="errors.zipcode"
          required
        />
      </div>

      <div>
        <BaseAutocomplete
          label="Select State"
          v-model="state"
          :searchArray="props.states"
          :fetchOnScroll="true"
          :dataLoading="dataLoading"
          :required="true"
          @loadMore="loadMoreStates"
          @filterData="filterStatesData"
        />
        <p v-if="errors.state" class="mt-1 first-letter:capitalize text-failure-500 text-xs">
          {{ errors.state }}
        </p>
      </div>
    </div>
    <div class="flex py-4" :class="!clientData ? 'justify-end' : 'justify-start'">
      <button
        type="submit"
        class="inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
      >
        Submit
      </button>
      <button
        v-show="!clientData"
        type="button"
        class="inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
        @click="$emit('closeModal')"
      >
        Cancel
      </button>
    </div>
  </form>
</template>

<script setup>
import { onMounted } from 'vue'
import { useField, useForm } from 'vee-validate'
import { object, string, boolean } from 'yup'
import { useMutation } from '@vue/apollo-composable'

import BaseAutocomplete from '@/components/layout//BaseAutocomplete.vue'
import BaseInput from '@/components/layout/BaseInput.vue'
import BaseCheckbox from '@/components/layout/BaseCheckbox.vue'
import CREATE_CLIENT from '@/graphql/mutations/createClient.gql'
import CREATE_ADDRESS from '@/graphql/mutations/createAddress.gql'

const props = defineProps({
  states: {
    type: Array,
    default: [],
  },
  clientData: {
    type: Object,
    default: null,
  },
  dataLoading: { type: Boolean, default: false },
})

const emit = defineEmits([
  'closeModal',
  'successToast',
  'updateField',
  'updateClient',
  'loadMoreStates',
  'filterStates',
])

const validationSchema = object({
  email: string().email(),
  name: string().required(),
  phone: string(),
  address: string().required(),
  city: string().required(),
  zipcode: string().required(),
  state: object().required(),
  companyName: string().nullable(),
  residenceOwner: boolean(),
}).test('eitherOr', 'Either Company Name or Residence Owner is required', function (value) {
  if (!value.companyName && !value.residenceOwner) {
    return false
  }

  return true
})

const { handleSubmit, errors } = useForm({
  validationSchema,
})

const { value: name, handleChange: handleNameChange } = useField('name')
const { value: email, handleChange: handleEmailChange } = useField('email')
const { value: phone, handleChange: handlePhoneChange } = useField('phone')
const { value: city, handleChange: handleCityChange } = useField('city')
const { value: address, handleChange: handleAddressChange } = useField('address')
const { value: zipcode, handleChange: handleZipcodeChange } = useField('zipcode')
const { value: state } = useField('state')
const { value: companyName, handleChange: handleCompanyNameChange } = useField('companyName')
const { value: residenceOwner, handleChange: handleResidenceOwnerChange } =
  useField('residenceOwner')
const { mutate: clientCreate, onDone: onClientAdd } = useMutation(CREATE_CLIENT)

const { mutate: addressCreate, onDone: onAddLocation } = useMutation(CREATE_ADDRESS)

onMounted(() => {
  if (props.clientData) {
    name.value = props.clientData.name
    email.value = props.clientData.email
    address.value = props.clientData.address.address1
    city.value = props.clientData.address.city
    zipcode.value = props.clientData.address.zipcode
    state.value = JSON.parse(JSON.stringify(props.clientData.address.state))
    phone.value = props.clientData.phone
    companyName.value = props.clientData.companyName
    residenceOwner.value = props.clientData.residenceOwner
  }
})

onClientAdd((clientData) => {
  emit('updateField', 'clientDetails', clientData.data.clientCreate.client)
  emit('closeModal')
  emit('successToast')
})

onAddLocation((result) => {
  emit('updateField', 'clientAddress', result.data.addressCreate.address)

  clientCreate({
    name: name.value,
    phone: phone.value,
    email: email.value,
    companyName: companyName.value,
    residenceOwner: residenceOwner.value,
    addressId: result.data.addressCreate.address.id,
  })
})

const onSubmit = handleSubmit(async (values) => {
  if (props.clientData) {
    emit('updateClient', values)
  } else {
    await addressCreate({
      address1: values.address,
      city: values.city,
      zipcode: values.zipcode,
      stateId: values.state.id,
    })
  }
})

const loadMoreStates = () => {
  emit('loadMoreStates')
}

const filterStatesData = (input) => {
  emit('filterStates', input)
}
</script>
