import { defineStore } from 'pinia'
import { computed } from 'vue'
import { useQuery } from '@vue/apollo-composable'

import GET_SERVICES_DATA from '@/graphql/queries/getServices.gql'
import GET_CATEGORIES from '@/graphql/queries/getCategories.gql'

export const useAdminStore = defineStore('admin', () => {
  const serviceDataQuery = useQuery(GET_SERVICES_DATA)
  const categoriesDataQuery = useQuery(GET_CATEGORIES)

  const serviceData = computed(() => serviceDataQuery.result?.value)
  const categoriesData = computed(() => categoriesDataQuery.result?.value)

  return {
    serviceData,
    categoriesData,
  }
})
