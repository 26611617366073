<template>
  <Loader v-if="loading || getProposalStatuses.loading.value" />
  <div v-else>
    <div class="print:hidden" v-if="router.currentRoute.value.name !== 'preview'">
      <Modal :open="statusesModal" title="Proposal Statuses">
        <div>
          <p class="col-span-2 text-lg font-semibold my-3">Update Proposal Status</p>
          <div class="grid grid-cols-2 items-center gap-4 max-w-4xl mx-auto px-8 my-10">
            <div v-for="status in getProposalStatuses.result.value.statuses">
              <BaseCheckbox
                :label="status"
                :checked="status.toLowerCase() === selectedStatus.toLowerCase()"
                @click="selectedStatus = status.toLowerCase()"
              />
            </div>
          </div>
        </div>
        <div class="mx-8 mb-8">
          <label class="block capitalize text-sm font-medium text-gray-700">Notes</label>
          <div v-if="userStore.userData.roles?.nodes[0].name == 'estimator'">
            {{ statusComments }}
          </div>
          <div v-else>
            <textarea
              class="w-full mt-1 block px-3 py-2 border rounded-md shadow-sm placeholder-gray-400 border-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              id="description"
              rows="3"
              v-model="statusComments"
              placeholder="Notes"
            ></textarea>
          </div>
        </div>
        <div class="flex justify-end mx-5 py-4">
          <button
            type="button"
            class="mr-auto inline-flex justify-center rounded-md border border-transparent bg-failure-600 ml-3 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-failure-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
            @click="statusesModal = false"
          >
            Cancel
          </button>
          <button
            type="submit"
            class="mx-2 inline-flex justify-center rounded-md border border-transparent bg-success-500 px-4 py-2 text-sm font-medium text-slate-50 hover:bg-success-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
            @click="handleStatusSubmit"
          >
            Submit
          </button>
        </div>
      </Modal>

      <div class="print:hidden grid grid-cols-5 gap-16 mb-10">
        <div class="col-span-3 overflow-hidden bg-white shadow rounded-lg">
          <div class="flex bg-primary-700 px-4 py-5 sm:px-6">
            <h3 class="text-lg items-center font-medium leading-6 text-white">Client Details</h3>
            <PencilSquareIcon
              @click="router.push(`/estimation/${result.proposal.id}/edit`)"
              class="ml-5 h-5 w-5 text-white"
            />
          </div>
          <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl class="grid grid-cols-1 gap-x-4 sm:grid-cols-2">
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Name</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0 line-clamp-2">
                  {{ proposalClientDetails.name }}
                </dd>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Email</dt>
                <dd
                  class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0 text-ellipsis overflow-hidden ..."
                >
                  {{ proposalClientDetails.email }}
                </dd>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Address</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                  <address class="text-sm text-block line-clamp-3">
                    {{ proposalClientDetails.address.address1 }}<br />
                    {{ proposalClientDetails.address.city }}
                    {{ proposalClientDetails.address.state.name }}
                    {{ proposalClientDetails.address.zipcode }}<br />
                    {{ proposalClientDetails.address.state.country.name }}
                  </address>
                </dd>
              </div>

              <div class="sm:col-span-2 py-4">
                <div class="w-full border-t border-gray-300" />
              </div>
              <div class="sm:col-span-2">
                <p>Project Details</p>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Project Name</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0 line-clamp-2">
                  {{ result.proposal.title }}
                </dd>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Project Address</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                  <address class="text-sm text-block line-clamp-3">
                    {{ proposalSupervisorDetails.address.address1 }}<br />
                    {{ proposalSupervisorDetails.address.city }}
                    {{ proposalSupervisorDetails.address.state.name }}
                    {{ proposalSupervisorDetails.address.zipcode }}<br />
                    {{ proposalSupervisorDetails.address.state.country.name }}
                  </address>
                </dd>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Supervisor Name</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0 line-clamp-2">
                  {{ proposalSupervisorDetails.name }}
                </dd>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Supervisor Phone</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                  {{ proposalSupervisorDetails.phone }}
                </dd>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Supervisor Email</dt>
                <dd
                  class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0 text-ellipsis overflow-hidden ..."
                >
                  {{ proposalSupervisorDetails.email }}
                </dd>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Notes</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0 line-clamp-2">
                  {{ result.proposal.description }}
                </dd>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Public Work</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                  {{ result.proposal.publicWorks ? 'Yes' : 'No' }}
                </dd>
              </div>
              <div class="py-2 sm:grid sm:grid-cols-5 sm:gap-4 sm:py-2 sm:px-2">
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Status</dt>
                <dd class="flex items-center mt-1 text-sm text-gray-900 sm:col-span-3 sm:mt-0">
                  {{ result.proposal.status }}
                  <span
                    ><PencilSquareIcon @click="statusesModal = true" class="ml-5 h-4 w-4"
                  /></span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div
          v-if="proposalServiceCalculations || route.name == 'subcontractors'"
          class="print:hidden col-span-2 bg-primary-50 overflow-hidden shadow sm:rounded-lg"
        >
          <div class="bg-primary-700 px-4 py-5 sm:px-6">
            <h3 class="text-lg font-medium leading-6 text-white">
              {{ proposalServiceCalculations?.serviceName }} - Estimation Totals
            </h3>
          </div>
          <div class="border-t border-gray-200">
            <dl>
              <div v-if="route.name == 'subcontractors'">
                <div
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Subcontractor</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(subcontractorTotal) }}
                  </dd>
                </div>
                <div
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Total</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(subcontractorTotal) }}
                  </dd>
                </div>
              </div>
              <div v-else-if="proposalServiceCalculations?.serviceName !== 'Striping'">
                <div
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">
                    Construction Materials
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(proposalServiceCalculations.constructionTotal) }}
                  </dd>
                </div>
                <div
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Equipment</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(proposalServiceCalculations.equipmentTotal) }}
                  </dd>
                </div>
                <div
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  proposalServiceCalculations
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Laborer</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(proposalServiceCalculations.laborerTotal) }}
                  </dd>
                </div>
                <div
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">
                    Miscellaneous Cost
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(proposalServiceCalculations.miscellaneousCostTotal) }}
                  </dd>
                </div>
                <div
                  v-if="proposalServiceCalculations.lchTotal !== 0"
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">LCH Total</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(proposalServiceCalculations.lchTotal) }}
                  </dd>
                </div>
                <div
                  v-if="
                    proposalServiceCalculations.gradingEquipment !== 0 &&
                    proposalServiceCalculations.serviceName !== 'Grading'
                  "
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Grading Equipment</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(proposalServiceCalculations.gradingEquipment) }}
                  </dd>
                </div>
                <div
                  v-if="
                    proposalServiceCalculations.gradingLabor !== 0 &&
                    proposalServiceCalculations.serviceName !== 'Grading'
                  "
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Grading Laborer</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(proposalServiceCalculations.gradingLabor) }}
                  </dd>
                </div>
              </div>
              <div v-else>
                <div
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Striping</dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(proposalServiceCalculations.constructionTotal) }}
                  </dd>
                </div>
                <div
                  class="px-4 border-b border-gray-300 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-sm font-medium text-gray-500 sm:col-span-2">
                    Miscellaneous Cost
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                    {{ formatNumber(proposalServiceCalculations.miscellaneousCostTotal) }}
                  </dd>
                </div>
              </div>
              <div
                v-if="proposalServiceCalculations"
                class="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
              >
                <dt class="text-sm font-medium text-gray-500 sm:col-span-2">Total</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                  {{ formatNumber(totalServiceCost) }}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div>
        <div class="print:hidden">
          <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select
              id="tabs"
              name="tabs"
              class="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option v-for="tab in tabs" :key="tab.name" :selected="tab.current">
                {{ tab.name }}
              </option>
            </select>
          </div>
          <div class="hidden sm:block">
            <nav class="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
              <router-link
                v-for="(tab, index) in tabs"
                :key="tab.name"
                :to="`/estimations/${route.params.id}/${tab.path}`"
                @click="updateTab(tab)"
                :class="[
                  tabClasses[index].base,
                  tabClasses[index].text,
                  tabClasses[index].rounded.left,
                  tabClasses[index].rounded.right,
                  tabClasses[index].disabled,
                ]"
                :aria-current="tab.current ? 'page' : undefined"
              >
                <p>{{ tab.displayName }}</p>
                <span
                  aria-hidden="true"
                  :class="[
                    tab.current ? 'bg-primary-100' : 'bg-transparent',
                    'absolute inset-x-0 bottom-0 h-0.5',
                  ]"
                />
              </router-link>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div>
      <router-view @disable-finalbid-tab="handleDisalbeFinalTab" :proposalData="proposalData" />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useQuery, useMutation } from '@vue/apollo-composable'
import { PencilSquareIcon } from '@heroicons/vue/20/solid'

import { formatNumber } from '@/utils/utility_methods'
import Loader from '@/components/layout/Loader.vue'
import Modal from '@/components/layout/Modal.vue'
import BaseCheckbox from '@/components/layout/BaseCheckbox.vue'
import { useUserStore } from '@/stores/user'
import { estimationTabs } from '@/keys/constants'

import GET_PROPOSAL_BY_ID from '@/graphql/queries/getProposalById.gql'
import GET_PROPOSAL_STATUSES from '@/graphql/queries/getProposalStatuses.gql'
import UPDATE_PROPOSAL from '@/graphql/mutations/updateProposal.gql'

const route = useRoute()
const router = useRouter()
const tabs = ref(estimationTabs)
const proposalData = ref(null)
const statusesModal = ref(false)
const selectedStatus = ref(null)
const statusComments = ref(null)
const disableFinalTab = ref(false)
const userStore = useUserStore()

const getProposalStatuses = useQuery(GET_PROPOSAL_STATUSES)
const { result, loading, refetch } = useQuery(GET_PROPOSAL_BY_ID, {
  id: route.params.id,
})
const updateProposal = useMutation(UPDATE_PROPOSAL)

onMounted(() => {
  updateTab({ name: route.name })
})

const proposalClientDetails = computed(() => {
  return result.value?.proposal?.client.nodes[0]
})

const proposalSupervisorDetails = computed(() => {
  return result.value?.proposal?.supervisor.nodes[0]
})

const proposalServiceCalculations = computed(() => {
  return result.value?.proposal?.serviceTotals.nodes.filter(
    (item) => item.serviceName == route.name
  )[0]
})

const tabTotals = computed(() => {
  const totals = {}
  result.value?.proposal?.serviceTotals.nodes.forEach((service) => {
    const totalSum =
      service.lchTotal +
      service.equipmentTotal +
      service.laborerTotal +
      service.miscellaneousCostTotal +
      service.constructionTotal

    totals[service.serviceName] =
      service.serviceName !== 'Grading'
        ? totalSum + service.gradingLabor + service.gradingEquipment
        : totalSum
  })
  return { ...totals, subcontractors: subcontractorTotal.value, Options: optionsTotal.value }
})

const totalServiceCost = computed(
  () =>
    proposalServiceCalculations.value.constructionTotal +
    proposalServiceCalculations.value.equipmentTotal +
    proposalServiceCalculations.value.laborerTotal +
    proposalServiceCalculations.value.miscellaneousCostTotal +
    proposalServiceCalculations.value.lchTotal +
    gradingTotals.value
)

const gradingTotals = computed(() =>
  proposalServiceCalculations.value.serviceName !== 'Grading'
    ? proposalServiceCalculations.value.gradingEquipment +
      proposalServiceCalculations.value.gradingLabor
    : 0
)

const subcontractorTotal = computed(() =>
  result.value.proposal.subcontractors.nodes.reduce((total, item) => total + item.cost, 0)
)

const optionsTotal = computed(() =>
  result.value.proposal.options.nodes.reduce((total, item) => total + item.estimatedCost, 0)
)

watch(result, (value) => {
  selectedStatus.value = value.proposal.status
  statusComments.value = value.proposal.statusComments
  proposalData.value = value
})

const updateTab = (selectedTab) => {
  tabs.value.map((item) => {
    item.name === selectedTab.name ? (item.current = true) : (item.current = false)
  })
}

const handleStatusSubmit = async () => {
  await updateProposal.mutate({
    input: {
      input: {
        id: result.value.proposal.id,
        status: selectedStatus.value,
        statusComments: statusComments.value,
      },
    },
  })
  statusesModal.value = false
  await refetch()
}

const handleDisalbeFinalTab = (finalTab) => {
  disableFinalTab.value = finalTab
}

const tabClasses = computed(() => {
  return tabs.value.map((tab, index) => ({
    base: 'group relative min-w-0 flex-1 overflow-hidden bg-primary-700 py-4 px-2 text-sm font-medium text-center hover:bg-primary-800 focus:z-10 flex items-center justify-center',
    text:
      tabTotals.value[tab.name] > 0
        ? 'text-gray-100 bg-success-600'
        : tab.current
        ? 'text-gray-100 bg-primary-800'
        : 'text-white hover:text-gray-100',
    rounded: {
      left: index === 0 ? 'rounded-l-lg' : '',
      right: index === tabs.value.length - 1 ? 'rounded-r-lg' : '',
    },
    disabled:
      tab.name === 'FinalBid' && disableFinalTab.value
        ? 'cursor-not-allowed pointer-events-none'
        : '',
  }))
})
</script>
