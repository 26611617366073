<template>
  <Loader v-if="loading" />
  <div v-else>
    <div class="flex my-4 px-1 flex-row justify-between items-center">
      <h1 class="text-2xl font-semibold text-gray-900">All Proposals</h1>
      <BaseButton
        type="button"
        @click="router.push('/estimations/new')"
        label="New Proposal"
        size="small"
      />
    </div>
    <div class="overflow-hidden bg-white shadow sm:rounded-md">
      <ul role="list" class="divide-y divide-gray-200">
        <div v-show="proposals.length == 0" class="py-44 relative flex justify-center items-center">
          <p class="px-2 text-xl text-gray-700">No data to display</p>
        </div>
        <Accordion
          v-for="proposal in proposalsList"
          @click="handleClick(proposal)"
          :proposal="proposal"
          :key="proposal.id"
        >
          <template v-slot:content>
            <div v-if="proposal.active" class="px-5">
              <FinalBidTab :proposalId="proposal.id" :editable="false" />
            </div>
          </template>
        </Accordion>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { useRouter } from 'vue-router'

import FinalBidTab from '@/components/layout/FinalBidTab.vue'
import BaseButton from '@/components/layout/BaseButton.vue'
import Loader from '@/components/layout/Loader.vue'
import Accordion from '@/components/layout/Accordion.vue'
import GET_PROPOSALS from '@/graphql/queries/getProposals.gql'

const proposalsList = ref([])
const router = useRouter()
const { result, loading } = useQuery(GET_PROPOSALS)
const proposals = computed(() => result.value?.proposals?.proposals.nodes ?? [])

watch(result, (value) => {
  proposalsList.value = value.proposals.proposals.nodes.map((obj) => ({ ...obj, active: false }))
})

const handleClick = (proposal) => {
  proposal.active = !proposal.active
}
</script>
